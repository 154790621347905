body .container{max-width: 1400px;}


.login-screen .regLnk a {
  font-weight: 600;
}
.form-div {
  position: relative;
}

.offerPoup .creat-button {
  background: #000;
  opacity: 1;
  border-color: #000;
  color: #fff;
  font-size: 16px;
  padding: 8px 15px;
  font-weight: 500;
  transition: all 0.5s;
}
.offerPoup .creat-button:hover {
  background-color: #fff;
  color: #000;
}

.offerPoup .creat-button:disabled {
  opacity: 0.7;
}

.cancel-button {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #fff;
  border-radius: 10px;
  margin: 10px auto;
  opacity: 1;
  color: #1B3CB7;
  text-align: center;
  font-size: 18px;
  max-width: 200px;
  padding: 8px 15px;
  text-decoration: none;
  transition: all 0.5s;
}
.cancel-button:hover {
  background-color:#1B3CB7;
  color: #fff;
  border-color: #1B3CB7;
}
.cancel-button:focus {
  border: 1px solid #0080fc;
}
.creat-button {
  background:#5ACAFA;
  border: 1px solid #5ACAFA;
  border-radius: 10px;
  margin: 10px auto;
  opacity: 1;
  color: #000;
  text-align: center;
  font-size: 18px;
  max-width: 200px;
  padding: 8px 15px;
  text-decoration: none;
  transition: all 0.5s;
}
.creat-button:focus,
.creat-button:hover {
  background-color: #fff;
  color: #000;
  border: 1px solid #1B3CB7;
}
.offerPoup.followSteps {
  width: auto;
}
.offerPoup.loginWidth {
  max-width: 400px;
}
.offerPoup {
  max-width: 700px;
}

.loginWidth .modal-content{background:none}

.offerPoup .cancel-button {
  background: #fff;
  opacity: 1;
  border:none;
  color: #1B3CB7;
  font-size: 16px;
  padding: 8px 15px;
  font-weight: 500;
  transition: all 0.5s;
}
.offerPoup .cancel-button:hover {
  background-color: #1B3CB7;
  color: #fff;
}
.offerPoup .cancel-button:focus {
  border: 1px solid #1B3CB7;
}

.offerPoup .creat-button {
  background: #5ACAFA;
  opacity: 1;
  border-color: #5ACAFA;
  color: #000;
  font-size: 16px;
  padding: 8px 15px;
  font-weight: 500;
  transition: all 0.5s;
}
.offerPoup .creat-button:hover {
  background-color: #fff;
  color: #000;
}

.offerPoup .creat-button:disabled {
  opacity: 0.7;
}
.offerPoup .creat-button:hover {
  background-color: #fff;
  color: #000;
}

.pop_content {
  position: relative;
  width: 100%;
  padding: 20px;
  background: linear-gradient(180deg, rgba(188,61,240,1) 0%, rgba(27,60,183,1) 100%);
  border-radius: 10px;
}

.pop_content h6 {
  font-weight: 700;
  margin: 0px;
  font-size: 16px;
  color: #000;
}
.pop_content .ttiCong {
  font-size: 18px;
  font-weight: 600;
  text-align: center;
  color: #383f4e;
}
.pop_content .subpopTTl {
  line-height: 20px;
  text-align: center;
}
.pop_content p {
  color: #383f4e;
  font-size: 14px;
}
.pop_content a {
  text-decoration: none;
  color: #fff;
  font-weight: normal;
}
.pop_content h2 {
  font-size: 22px;
  text-align: center;
  font-weight: 600;
  color: #fff;
}

.pop_content p {
  text-align: center;
  font-size: 14px;
  font-weight: 600;
}

.pop_content .blueHead {
  color: #0080fc;
}

.login-screen input[type="text"],
.login-screen input[type="password"],
.login-screen input[type="date"],
.login-screen input[type="datetime"],
.login-screen input[type="email"],
.login-screen input[type="number"],
.login-screen input[type="search"],
.login-screen input[type="tel"],
.login-screen input[type="time"],
.login-screen input[type="url"],
.login-screen textarea,
.login-screen select {
  border: 1px solid #e6ecf5;
  background: #fff;
  border-radius: 10px;
  font-size: 14px;
  height: auto;
  margin: 0;
  outline: 0;
  padding: 15px;
  width: 100%;
  background-color: #ffffff;
  color: #000;
  position: relative;
  /* margin-bottom: 0; */
}

.login-screen .form-div {
  margin-bottom: 20px;
}
.login-screen .inputWithIcon input {
  padding-left: 55px;
}
.login-screen .poupBox {
  padding: 25px;
  background: #162895;
  border-radius: 10px;
}
.inputWithIcon {
  position: relative;
}
.inputWithIcon .icon {
  position: absolute;
  z-index: 2;
  height: 33px;
  line-height: 30px;
  width: 45px;
  text-align: center;
  border-right: 1px solid #e6ecf5;
  top: 10px;
  left: 0;
  color: #0080fc;
  font-size: 18px;
}
.inputWithIcon .icon img {
  max-width: 20px;
}

.login-screen label {
  padding-bottom: 5px;
  font-weight: 500;
  font-size: 18px;
}

.login-screen p {
  text-align: center;
  font-size: 14px;
  margin: 0px;
  color: #fff;
  line-height: 22px;
}

.login-screen .hr5 {
  border-top: 5px solid #c2c2c2;
}
.login-screen h3 {
  font-size: 14px;
  font-weight: 600;
  color: #000;
}

.login-screen .regLnk a {
  font-weight: 600;
  color:#F3A76D;
}

.login-screen p span {
  font-size: 16px;
  font-weight: 700;
}

.login-screen-button {
  /* UI Properties */
  background: #0080fc;
  border-radius: 10px;
  opacity: 1;
  width: 100%;
  text-align: center;
  /* margin-top:15px; */
  border: 0px;
  font-size: 18px;
  padding: 15px 0px;
  color: #fff;
}

.form-section .form-div {
  margin-bottom: 20px;
}

.form-div {
  position: relative;
}

.form-div .accordion-button {
  border-radius: 10px !important;
}

.form-div label {
  padding-left: 4px;
}

.login-screen .form-div {
  margin-bottom: 20px;
}

.eye-icon {
  right: 17px;
  top: 21px;
  cursor: pointer;
  position: absolute;
  font-size: 15px;
  color: #808080;
}

.countryNumber .css-13cymwt-control,
.countryNumber .css-t3ipsp-control {
  height: 40px;
  min-height: 52px;
  border: 1px solid #e6ecf5;
  border-radius: 10px;
}

.countryNumber .css-13cymwt-control span.small,
.countryNumber .css-t3ipsp-control span.small {
  display: flex;
  align-items: center;
  width: 110px;
}

.countryNumber .countryCode__menu {
  z-index: 9;
  width: 280px;
}
.countryNumber .countryCode__menu div {
  max-height: 200px;
  font-size: 13px;
  padding: 2px;
}
.countryNumber .countryCode__menu input {
  display: none;
}

.countryNumber .css-1hb7zxy-IndicatorsContainer {
  display: none;
}
.countryNumber .css-13cymwt-control span.small,
.countryNumber .css-t3ipsp-control span.small {
  width: 106px;
}
.countryNumber .css-13cymwt-control span.small,
.countryNumber .css-t3ipsp-control span.small {
  width: 106px;
}
.countryNumber .css-13cymwt-control .css-1fdsijx-ValueContainer,
.countryNumber .css-t3ipsp-control .css-1fdsijx-ValueContainer {
  padding: 2px;
}
.countryNumber .countryCode__menu {
  z-index: 9;
  width: 230px;
}
.check-box-div {
  /* width: 50%;
    float: left; */
  font-size: 15px;
  display: flex;
}

.check-box-div span {
  margin-top: 0px;
  font-size: 15px;
  color: #fff;
  /* position: absolute; */
  margin-left: 13px;
  font-weight: normal;
}
.check-box-div input {
  height: 25px;
  width: 25px;
}
.check-box-div span a {
  color: #F3A76D;
  font-weight: bold;
}
.check-box-div {
  width: 100%;
  text-align: left;
}
.pflLoginBtn .dropdown.loginBtn .userProfile {
  color: #000;
  background: none;
}
.userProfile {
  background: none;
  border: none;
  font-size: 16px;
}
.userProfile:hover,
.userProfile:active,
.userProfile.show {
  background: none !important;
  color: #000;
}
.userProfile img {
  width: 38px;
  height: 38px;
  border-radius: 50%;
  margin-right: 5px;
}
.userProfile:after {
  display: none;
}
.userProfile i {
  font-size: 20px;
  display: inline-block;
  margin-top: 5px;
  margin-left: 20px;
}
.userProfile i {
  margin: 0;
}

.videoFile .videoPreview {
  height: 80px;
  width: 100px;
  background: #ddd;
  border-radius: 10px;
  object-fit: cover;
}

.videoFile h3 {
  color: #dcdfe4;
  font-weight: 900;
  font-size: 3em;
  line-height: 58px;
  margin-top: 50px;
  height: auto;
}
.videoFile p {
  color: #bfc7d4;
  font-size: 1.1875em;
  line-height: 29px;
  text-align: center;
  text-decoration-color: #bfc7d4;
}
.videoFile label {
  color: #bfc7d4;
}
.outerBox {
  position: relative;
  border-radius: 5px;
  /*border: 3px dash #BEC0DA;*/
  padding: 80px 50px 60px;
  box-shadow: 0 0 15px #e2dcf3;
  border-radius: 15px;
}

@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700&display=swap');
body {
    padding: 0;
    margin: 0;
    font-size: 15px;
    background: #f5f6fa;
    color: #3a3a3a;
    font-family: 'Montserrat', sans-serif !important;
    font-weight: 500;
  }
  body * {
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
  }
  ul,
  ul li {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  input,
  button {
    outline: none;
    border: none;
    box-shadow: none;
  }
  textarea {
    min-height: 100px;
  }
  a,
  a:hover,
  a:focus {
    text-decoration: none;
    outline: none;
    transition: all 0.5s;
    -webkit-transition: all 0.5s;
    -moz-transition: all 0.5s;
  }
  a:active,
  a:hover {
    outline: 0;
  }
  :focus {
    outline: none;
    border: none;
  }
  p {
    margin-bottom: 15px;
    padding: 0;
    line-height: 22px;
  }
  p:last-child,
  p:last-of-type,
  p:only-child {
    margin-bottom: 0px;
  }
  figure {
    margin: 0;
    position: relative;
  }
  figure img {
    position: relative;
    z-index: 1;
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    padding: 0;
    margin: 0;
    font-weight: 300;
  }
  
  .pflHeader {
    background: #fff;
    padding: 15px 0;
    position: sticky;
    top: 0;
    z-index: 99;
    box-shadow: 0 0 10px #999;
  }
  .pflLoginBtn {
    text-align: right;
  }
  .pflHeader .topLogo {
    display: flex;
    align-items: center;
  }
  .pflHeader .topLogo img {
    max-width: 175px;
  }
  .pflHeader .topLogo a {
    display: block;
    position: relative;
    color: #000;
    text-decoration: none;
  }
  .pflHeader .topLogo a:first-child {
    padding-right: 15px;
    margin-right: 15px;
  }
  /* .pflHeader .topLogo a:first-child:after {
    content: "";
    position: absolute;
    height: 30px;
    width: 2px;
    background: #ccc;
    top: -3px;
    right: -3px;
  } */
  
  .pflLoginBtn .dropdown.loginBtn .userProfile {
    color: #000; background: none;
  }


  
  .pflLoginBtn .bothBtn {
    color: #000;
    background: #fff;
    font-size: 14px;
    padding: 5px 15px;
    display: inline-block;
    border-radius: 30px;
    font-weight: 500;
    border: 1px solid #000;
  }
  
  .pflLoginBtn .bothBtn button {
    color: #000000;
    background: none;
    border: 0px;
    font-weight: 500;
  }
  
  .pflLoginBtn a {
    color: #242527;
    background: #fff;
    font-size: 14px;
    padding: 5px 15px;
    display: inline-block;
    border-radius: 30px;
    font-weight: 500;
  }
  
  .homeBanner {
    text-align: center;
    position: relative;
  }
  
  .homeBanner .offer{width:180px; position:absolute; bottom:-50px; right:25%; filter:drop-shadow(0px 0px 10px #000);}
  .homeBanner img{width:100%;}
  
  h3.titl {
    font-size: 26px;
    text-transform: uppercase;
    font-weight: bold;
    color: #000;
  }
  
  .ticketBox:hover {
    transform: scale(1.02);
    box-shadow: 0 0 8px #999;
  }
  .ticketBox {
    /* display: flex; */
    display: inline-block;
    background: #fff;
    border-radius: 15px;
    /* overflow: hidden; */
    box-shadow: 0 0 5px #ddd;
    transition: transform 0.2s;
    text-align: left;
  }
  .ticketBox figure {
    margin: 0px; /* width: 61%; */
  }
  .ticketBox figure img {
    border-radius: 10px 10px 0 0;
  }
  
  .ticketBox.ticketBoxDetail:hover {
    transform: none;
  }
  .ticketBox.ticketBoxDetail figure {
    width: 48%;
  }
  .ticketBox img.ticketImg {
    width: 100%;
    border-radius: 15px 15px 0 0;
    /* width: 280px; */
    /* height: 200px; 
    object-fit: cover;*/
  }
  .ticketBox .textCont:before {
    content: "";
    position: absolute;
    height: 30px;
    width: 30px;
    background: #f5f6fa;
    border-radius: 100%;
    right: -12px;
    bottom: -16px;
  }
  .ticketBox .textCont:after {
    content: "";
    position: absolute;
    height: 30px;
    width: 30px;
    background: #f5f6fa;
    border-radius: 100%;
    left: -12px;
    bottom: -16px;
  }
  .ticketBox .textCont {
    padding: 20px 30px;
    margin-bottom: 20px;
    /* padding: 20px 80px 20px 25px; */
    flex: 0 0 55%;
    position: relative;
    /* border-bottom:2px dashed #959494; */
    border-bottom: 2px dashed #bbb;
  }
  .ticketBox .textCont .ticketDate {
    position: relative;
    /* position: absolute;
    height: 100%;
    width: 30px;
    background: url(/public/images/ticket-border.png) no-repeat center center;
    top: 0;
    right: 20px; */
  }
  
  .ticketBoxDetail.ticketBox .textCont h3 {
    min-height: auto;
    margin-right: 20px;
    white-space: normal;
  }
  .ticketBoxDetail.ticketBox .textCont::after,
  .ticketBoxDetail.ticketBox .textCont::before {
    display: none;
  }
  .ticketBoxDetail.ticketBox .textCont {
    border: 0px;
    margin: 0px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .ticketBoxDetail.ticketBox .pricBtn {
    padding: 0px;
    display: flex;
    align-items: center;
  }
  .ticketBoxDetail.ticketBox .pricBtn ul {
    display: block;
    width: 100%;
  }
  .ticketBox .textCont .ticketDate.ticketDateDetail {
    background: none;
    position: absolute;
    top: 0;
    right: 20px;
    height: 100%;
    width: 30px;
  }
  .ticketBox .textCont .ticketDate.ticketDateDetail::after {
    content: "";
    position: absolute;
    left: 14px;
    right: auto;
    z-index: -1;
    border-right: 2px dashed #9f9f9f;
    height: 100%;
    top: 0;
  }
  .ticketBox .textCont .ticketDate .roundDate span {
    color: #fff;
    font-size: 35px;
    display: block;
    font-weight: 600;
    line-height: 32px;
  }
  
  .ticketBox .textCont .ticketDate .roundDate {
    position: absolute;
    top: -140px;
    right: 0px;
    z-index: 9;
    height: 80px;
    width: 80px;
    background: #3c97f2;
    color: #fff;
    text-align: center;
    border-radius: 100%;
    border: 3px solid #fff;
    box-shadow: 0 0 5px #ddd;
    line-height: 16px;
    font-size: 16px;
    padding: 5px;
  }
  .ticketBox .pricBtn {
    /* margin: auto; */
    padding: 0 30px 20px;
  }
  .ticketBox .pricBtn ul {
    display: flex;
    justify-content: space-between;
    list-style: none;
    margin: 0px;
    padding: 0px;
    align-items: center;
  }
  .ticketBox.ticketBoxDetail .ticketBox .pricBtn ul {
    display: contents;
  }
  
  .ticketBox .pricBtn .price {
    color: #000;
    font-size: 20px;
    font-weight: 600;
    /* margin: 0 20px; */
  }
  .ticketBox .pricBtn .price > span {
    color: #383f4e;
    font-size: 14px;
    font-weight: 500;
    display: block;
  }
  
  .explorBtn.wht {
    background: #fff;
    color: #000;
    border: 1px solid #fff;
  }
  .explorBtn.wht:hover {
    background: none;
    color: #fff;
  }
  
  .explorBtn {
    background: #000;
    border: 1px solid #000;
    padding: 8px 30px;
    color: #fff;
    border-radius: 10px;
    font-size: 16px;
    transition: all 0.5s;
  }
  
  .explorBtn:hover{background:#fff; color:#000;}
  .futrEvent.celebrityGuests .ftrImg{border-radius:50px 0 0 50px;}
  .futrEvent.celebrityGuests .contBox{right:auto; left:-70px; z-index:2;}
  
  .guestTicket{position:relative;}
  .guestTicket .guestDetail{position: absolute;top: 0; z-index: 2; width: 100%; text-align:center;}
  .guestTicket .guestDetail p{font-size:18px; font-weight:500; color:#FFF;}
  
  
  .textCont p {
    color: #383f4e;
    font-size: 16px;
    margin: 0px;
    font-weight: 500;
    line-height: 20px;
  }
  .textCont h3 {
    font-size: 18px;
    color: #000;
    font-weight: 600;
    /* min-height:40px;*/
    max-width: 435px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  
  .textCont h4 {
    font-size: 15px;
    color: #000;
    font-weight: 600;
  }
  
  .selectInput select {
    border: 1px solid #c5c7cb;
    border-radius: 6px;
    padding: 7px;
    margin-bottom: 5px;
  }
  .contBox {
    position: relative;
    background: #fff;
    padding: 30px;
    border-radius: 20px;
    box-shadow: 0 0 5px #ddd;
    top: 0;
    right: -50px;
    width: 500px;
  }
  
  .contBox .titl2 {
    font-size: 18px;
    color: #000;
    /* text-transform: uppercase; */
    line-height: 26px;
    font-weight: 600;
  }
  
  .futrEvent .ftrImg {
    border-radius: 0 50px 50px 0;
    width: 100%;
    height: 550px;
    object-fit: cover;
  }
  
  .contBox.textCont p {
    color: #383f4e;
  }
  
  .contBox .roundDate {
    position: absolute;
    top: 80px;
    right: 30px;
    height: 100px;
    width: 100px;
    background: #3c97f2;
    color: #fff;
    text-align: center;
    border-radius: 100%;
    border: 3px solid #fff;
    box-shadow: 0 0 5px #ddd;
    line-height: 22px;
    font-size: 18px;
    padding: 12px;
  }
  .contBox .roundDate span {
    color: #fff;
    font-size: 40px;
    display: block;
    font-weight: 600;
    line-height: 32px;
  }
  .contBox .shareBtn {
    background: none;
    border: 0px;
    position: absolute;
    right: 20px;
    top: 25px;
  }
  
  .contBox .shareBtn button.btn {
    background: none;
    border: 0;
    padding: 0px;
  }
  
  .tktDetail {
    position: relative;
    /* padding: 100px 0;
    background: url(/public/images/ticket-detail-bg.png) no-repeat center center;
    background-size: 100% 100%;
    background-size: cover; */
  }
  
  .tktDetail .tktbgImages {
    width: 100%;
  }
  .tktDetail .container {
    position: absolute;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
    z-index: 2;
  }
  
  .tktDetail:after {
    content: "";
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: rgba(0, 0, 0, 0.7);
  }
  .tktDetail .ticketBox {
    position: relative;
    z-index: 1;
    box-shadow: none;
    border-radius: 15px;
    display: flex;
    overflow: hidden;
  }
  .tktDetail .ticketBox img.ticketImg {
    width: 100%;
    border-radius: 0px;
    /* height: 350px; */
  }
  
  .countryNumber .css-13cymwt-control,
  .countryNumber .css-t3ipsp-control {
    height: 40px;
    min-height: 52px;
    border: 1px solid #e6ecf5;
    border-radius: 10px;
  }
  
  .countryNumber .css-13cymwt-control span.small,
  .countryNumber .css-t3ipsp-control span.small {
    display: flex;
    align-items: center;
    width: 110px;
  }
  
  .countryNumber .countryCode__menu {
    z-index: 9;
    width: 280px;
  }
  .countryNumber .countryCode__menu div {
    max-height: 200px;
    font-size: 13px;
    padding: 2px;
  }
  .countryNumber .countryCode__menu input {
    display: none;
  }
  
  .countryNumber .css-1hb7zxy-IndicatorsContainer {
    display: none;
  }
  
  .tktDetail .ticketBox .shareBtn.dropdown a {
    color: #000;
    font-size: 15px;
    display: flex;
    align-items: center;
  }
  .tktDetail .ticketBox .shareBtn.dropdown a svg {
    color: #171818;
    font-size: 18px;
    margin-right: 8px;
  }
  
  .tktDetail .ticketBox .shareBtn {
    position: absolute;
    top: 20px;
    right: 20px;
  }
  .tktDetail .ticketBox .shareBtn button {
    background: none;
    padding: 0;
    border: 0;
  }
  .tktDetail .ticketBox .shareBtn button::after {
    content: "";
    display: none;
  }
  
  /* .tktDetail .ticketBox .ticketDate { } */
  
  .tktDetail .ticketBox .textCont {
    flex: 0 0 67%;
  }
  .circle,
  .circle_2 {
    width: 30px;
    height: 30px;
    background: #000;
    border-radius: 50%;
    position: relative;
    top: -11px;
  }
  .circle_2 {
    bottom: -10px;
    top: auto;
    right: 0;
    position: absolute;
    left: auto;
  }
  .tktDetail .explorBtn {
    background: #000;
    color: #fff;
    transition: all 0.5s;
    height: 48px;
  }
  .tktDetail .explorBtn svg {
    margin-left: 10px;
  }
  .tktDetail .explorBtn.imgInvert:hover img {
    filter: invert(1);
  }
  .tktDetail .ticktDisc {
    padding: 20px;
    border-top: 2px dashed #9f9f9f;
  }
  .tktDetail .explorBtn:hover {
    background-color: #fff;
    color: #000;
  }
  .tktDetail .ticktDisc h3 {
    color: #383f4e;
    font-size: 14px;
    font-weight: 500;
  }
  .tktDetail .ticktDisc p {
    font-size: 14px;
    color: #000;
    font-weight: 400;
    line-height: inherit;
  }
  .tktDetail .ticktDisc .buyNow {
    background: #3c97f2;
    color: #fff;
    font-weight: 500;
    padding: 8px 30px;
    border-radius: 6px;
    display: inline-block;
    border: none;
  }
  
  .benefits {
    background: #fff;
    padding: 50px 0;
  }
  .benefits h3 {
    color: #000;
    font-size: 20px;
    margin-bottom: 0px;
    font-weight: 500;
  }
  /* .benefits ul {
    padding: 0;
    margin: 0;
    list-style: none;
    display: flex;
    justify-content: space-between;
    overflow: hidden;
  }
  .benefits ul li {
    min-width: 200px;
    max-width: 200px;
  }
  .benefits ul li + li {
    margin-left: 20px;
  } */
  
  /* .benefits .owl-carousel img{cursor: pointer;} */
  
  .perkSectn {
    padding: 50px 0;
  }
  .perkSectn h3 {
    color: #000;
    font-size: 20px;
    margin-bottom: 20px;
    font-weight: 500;
  }
  .perkSectn div[class*="col-"]:nth-child(2) p {
    background: #3c97f2;
    color: #fff;
  }
  .perkSectn div[class*="col-"]:nth-child(3) p {
    background: #3c97f2;
    color: #fff;
  }
  .perkSectn div[class*="col-"]:nth-child(6) p {
    background: #3c97f2;
    color: #fff;
  }
  .perkSectn div[class*="col-"]:nth-child(7) p {
    background: #3c97f2;
    color: #fff;
  }
  
  .perkSectn p {
    background: #fff;
    box-shadow: 0 0 6px #9d9d9d;
    padding: 10px 20px;
    border-radius: 10px;
    height: 100%;
    display: flex;
    width: 100%;
    align-items: center;
  }
  
  .luckyBox_1,
  .luckyBox {
    border: 1px solid #be7a13;
    padding: 15px 20px;
    border-radius: 10px;
    background: #fff;
    min-height: 106px;
    font-size: 15px;
    font-weight: 500;
    line-height: 25px;
    position: relative;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
  }
  .luckyBox {
    border-color: #e10c00;
  }
  .luckyBox_1 img,
  .luckyBox img {
    position: absolute;
    right: -1px;
    bottom: -21px;
  }
  .graphic .graImg {
    position: absolute;
    top: -36px;
    height: 165px;
    text-align: center;
    left: 23px;
    right: 0;
    bottom: -71px;
  }
  .graphic .graImg_1 {
    position: absolute;
    top: -99px;
    height: 232px;
    text-align: center;
    left: 0;
    right: 0;
  }
  .graphic {
    position: relative;
  }
  /*=============== Benefit Popup Page ===========================*/
  .popup_close {
    background: none;
    border: none;
    position: absolute;
    right: -70px;
    top: -10px;
  }
  .modal-backdrop {
    --bs-backdrop-opacity: 0.8;
  }
  .userProfile {
    background: none;
    border: none;
    font-size: 16px;
    cursor:pointer;
  }
  .userProfile:hover,
  .userProfile:active,
  .userProfile.show {
    background: none !important;
    color: #fff;
  }
  .userProfile img {
    width: 38px;
    height: 38px;
    border-radius: 50%;
    margin-right: 5px;
    object-fit: cover;
  }
  .userProfile:after {
    display: none;
  }
  .userProfile i {
    font-size: 20px;
    display: inline-block;
    margin-top: 5px;
    margin-left: 20px;
  }
  .loginBtn.userLogin .dropdown-menu {
    width: 210px;
    box-shadow: 0 20px 20px rgba(0, 0, 0, 0.54);
    padding: 4px 15px;
  }
  .loginBtn.userLogin .dropdown-menu li + li {
    border-top: 1px solid #ccc;
  }
  .loginBtn.userLogin .dropdown-menu li a {
    color: #000;
    font-size: 16px;
    font-weight: 500;
    padding: 14px 0;
    border-radius: 0;
  }
  .loginBtn.userLogin .dropdown-menu li a:hover {
    background: none;
    color: #3c97f2;
  }
  .loginBtn.userLogin .dropdown-menu li a img {
    margin-right: 5px;
    max-width: 18px;
    max-height: 18px;
  }
  /*=============== Benefit Popup Page ===========================*/
  .marquee {
    overflow: hidden;
  }
  
  .marquee-content {
    display: flex;
    animation: scrolling 50s linear infinite;
  }
  .marquee-content:hover {
    animation-play-state: paused;
    cursor: pointer;
  }
  .marquee-item {
    flex: 0 0 17vw;
    margin: 0 0px;
  }
  
  .marquee-item img {
    display: block;
    width: 100%;
    padding: 0 10px;
  }
  
  @keyframes scrolling {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translatex(-100vw);
    }
  }
  
  
  .pop_content.qrCode {
    padding: 0;
  }
  .pop_content.qrCode h3 {
    font-size: 14px;
    font-weight: 500;
    color: #000;
  }
  .pop_content.qrCode .qrBox {
    /*background:#E8E8E8;*/
    background: transparent
      linear-gradient(
        71deg,
        #2e63a1 0%,
        #4f59e2 27%,
        #a85faa 56%,
        #eb6f7e 73%,
        #e1a359 100%
      )
      0% 0% no-repeat padding-box;
    padding: 25px;
    text-align: center;
    border-radius: 10px;
  }
  .pop_content.qrCode .qrBox p {
    font-size: 15px;
    color: #000;
    font-weight: 400;
  }
  .pop_content.qrCode .rate {
    padding: 30px 20px;
    text-align: center;
    border-top: 2px dashed #656565;
  }
  .pop_content.qrCode .availBenefits {
    padding: 20px 20px;
    border-bottom: 2px dashed #656565;
  }
  .pop_content.qrCode .availBenefits ul {
    display: flex;
    flex-wrap: wrap;
    margin-top: 15px;
  }
  .pop_content.qrCode .availBenefits ul li {
    text-align: center;
    padding: 0 35px 10px 0;
    width: 25%;
  }
  .pop_content.qrCode .availBenefits ul li p {
    font-size: 10px;
    color: #000;
    font-weight: 500;
    line-height: 16px;
    margin-top: 5px;
  }
  .barcodeBg {
    padding: 0;
    /* background: url(/public/images/barcode_bg.png) no-repeat center center; */
    position: relative;
    height: 172px;
    width: 212px;
    margin: 0 auto;
  }
  .barcodeBg img {
    padding-top: 16px;
  }
  .backBenefit {
    background: none;
    text-decoration: underline;
    font-size: 14px;
    font-weight: normal;
  }
  
  .seoPages{min-height: calc(100vh - 285px); display:flex; align-items:center;}
  .seoPages .pageBox{text-align:center; background:#FFF; padding:10px; border-radius:10px; color:#000;}
  .seoPages .pageBox h2{font-size:22px; font-weight:500; line-height:30px;}
  .seoPages .pageBox h3{font-size:22px; font-weight:600;}
  
  /*=============== Profile Page ===========================*/
  .profileMain{padding-left:0px; padding-right:0px; min-height:100vh; height:100%; background:#000; transition: all 0.5s;}
  .menuSectionLeft{width:300px; left:-300px; position: absolute;transition: all 0.5s; z-index: 11;top: 0;}
  .profileMain.show{padding-left:300px;}
  .profileMain.show .menuSectionLeft{left:0px;}
  
  .toggleBtn{display:block;width:40px;height:40px;background:none;color:#fff;font-size:30px;line-height:16px;text-align:center;border-radius:50%;position:absolute;right:-45px;top:5px;z-index:2; transition: all 0.5s;}
  .profileMain.show .toggleBtn{right:0px; transition: all 0.5s;}

  .profileMain.show .toggleBtn .cross{display:block;}
  .profileMain .toggleBtn .cross{display:none;}
  
  .profileMain.show .toggleBtn .humber{display:none;}
  .profileMain .toggleBtn .humber{display:block;}


  .profileBox {
    box-shadow: 0 0 10px rgba(113, 113, 113, 0.16);
    padding: 40px;
    border-radius: 5px;
    background: rgb(188,61,240);background: linear-gradient(180deg, rgba(188,61,240,1) 0%, rgba(27,60,183,1) 100%);
    /* background-color: #62b833; */
  }
  .pro_pic img {
    border-radius: 50%;
    margin-right: 15px;
    width: 150px;
    height: 150px;
    object-fit: cover;
  }
  .pro_pic h4 {
    font-size: 20px;
    color: #000;
    font-weight: 600;
    margin: 0;
  }
  .pro_pic a {
    color: #000;
    font-size: 14px;
    font-weight: 400;
    text-decoration: underline;
  }
  .profileMain hr {
    border-width: 5px;
    border-color: #b1acac;
    margin: 38px 0;
  }
  .cardBox {
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    position: relative;
    background: #fff;
    min-height: 364px;
    margin-bottom: 30px;
  }
  .cardBox h4 {
    font-size: 15px;
    color: #000;
    font-weight: 600;
  }
  .cardBox figure,
  .cardBox figure img {
    border-radius: 10px 10px 0 0;
    margin: 0;
    padding: 0;
  }
  .cardBox figure img.eventImage {
    height: 115px;
    object-fit: cover;
  }
  .cardBox h2 {
    color: #000;
    font-size: 15px;
    font-weight: 600;
    /* text-transform: uppercase; */
    margin: 0 0 10px 0;
    padding: 0;
    height: 36px;
  }
  .cardBox p {
    color: #383f4e;
    font-size: 16px;
    font-weight: 500;
    /* text-transform: uppercase; */
    margin: 0 0 20px 0;
  }
  .cardBox p.discription {
    height: 45px;
    overflow: hidden;
    text-transform: capitalize;
    font-weight: 400;
    line-height: normal;
    font-size: 14px;
  }
  .cardBox p i {
    font-style: normal;
    display: block;
    color: rgba(56, 63, 78, 0.6);
    font-size: 12px;
    font-weight: normal;
  }
  .cardBox .price span {
    color: #383f4e;
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 15px;
    display: block;
  }
  .cardBox .viewBtn_2 a {
    transition: all 0.5s;
    /* display: inline-block;
    width: 146px;
    height: 46px;
    color: #fff;*/
    border-radius: 5px;
    line-height: 46px;
    background: #fff;
    text-align: center;
    font-size: 16px;
    font-weight: normal;
    width: 146px;
    height: 48px;
    border: 1px solid #8a98ba;
    border-radius: 5px;
    opacity: 1;
  }
  .cardBox .viewBtn_2 a.ViewDetail {
    width: 146px;
    color: #fff;
    height: 48px;
    border: 1px solid var(--unnamed-color#3C97F2);
    background: #000;
    border: 1px solid #000;
    border-radius: 5px;
    opacity: 1;
  }
  .cardBox .viewBtn_2 a:hover {
    background-color: #fff;
    color: #000;
  }
  .proDate {
    position: absolute;
    top: 30px;
    right: 25px;
    height: 70px;
    width: 70px;
    background: #3c97f2;
    color: #fff;
    text-align: center;
    border-radius: 100%;
    border: 3px solid #fff;
    box-shadow: 0 0 5px #ddd;
    line-height: 16px;
    font-size: 14px;
    padding: 5px;
    z-index: 1;
  }
  .proDate span {
    color: #fff;
    font-size: 25px;
    display: block;
    font-weight: 600;
    line-height: 20px;
  }
  .cardBox figure figcaption {
    background: #3c97f2;
    display: inline-block;
    color: #fff;
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 1;
    padding: 1px 10px;
    font-size: 13px;
  }
  
  .cardBox figure figcaption::after {
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 0 14px 21px 0px;
      border-color: transparent transparent #3c97f2 transparent;
      content: "";
      position: absolute;
      right: -14px;
      bottom: 0;
  }
  
  .sharePro {
    position: absolute;
    top: 15px;
    left: 15px;
    z-index: 2;
  }
  
  .sharePro .shareBtn button.btn {
    background: none;
    border: 0px;
  }
  .sharePro .shareBtn button.btn img {
    width: auto;
  }
  .sharePro .shareBtn button.btn {
    background: none;
    border: 0px;
    padding: 0;
  }
  .sharePro .shareBtn button.btn::after {
    display: none;
  }
  
  .tabing {
    padding: 0 0 20px;
  }
  .tabing li {
    display: inline-block;
    margin-right: 15px;
  }
  .tabing li a {
    display: block;
    border: 1px solid #000;
    width: 130px;
    height: 42px;
    line-height: 42px;
    border-radius: 10px;
    text-align: center;
    font-size: 14px;
    font-weight: 500;
    color: #000;
    transition: all 0.5s;
  }
  .tabing li.pro{margin-left: auto;}
  .tabing li.pro a{background: transparent linear-gradient(119deg, #F3A76D 0%, #BC3DF0 100%) 0% 0%;width:160px; height: 40px;border-radius: 5px;font-size: 15px;font-weight:500;color: #fff;display: flex;align-items: center;padding:0; justify-content: center; text-decoration: none;}
  .tabing li.pro a svg{font-size:28px; margin-right: 10px;}
  .tabing li.pro a:hover,
  .tabing li.pro a.active
  {background: transparent linear-gradient(119deg, #F3A76D 0%, #BC3DF0 100%) 0% 0% no-repeat padding-box;}
  .tabing li a.active,
  .tabing li a:hover {background: #000;color: #fff;border-color: #000;}
  
  .collecTitle {
    color: #000;
    font-size: 18px;
    font-weight: 500;
  }
  
  .appDownlod {
    background: #3c97f2;
    padding: 80px 0;
    margin: 200px 0 150px;
    position: relative;
    border-radius: 20px;
    color: #fff;
  }
  .appDownlod:after {
    content: "";
    position: absolute;
    height: 80px;
    width: 80px;
    background: #54aff3;
    border-radius: 100%;
    right: 20px;
    bottom: 100px;
  }
  .appDownlod:before {
    content: "";
    position: absolute;
    height: 200px;
    width: 200px;
    background: #54aff3;
    border-radius: 100%;
    left: 27%;
    bottom: 70px;
  }
  .appDownlod p {
    font-size: 16px;
  }
  .appDownlod h3 {
    font-size: 28px;
    font-weight: 600;
  }
  .appDownlod .webMobile {
    position: absolute;
    top: -120px;
    left: 100px;
    width: 296px;
    z-index: 3;
  }
  .appDownlod .playStoreBoth {
    position: relative;
    z-index: 2;
  }
  /* .appDownlod .webMobile .owl-item img{border-radius:25px;} */
  .appDownlod .webMobile .owl-carousel .owl-stage-outer {
    border-radius: 27px;
  }
  .appDownlod .webMobile::after {
    content: "";
    position: absolute;
    height: 607px;
    width: 312px;
    background: #3c4250;
    border-radius: 30px;
    top: -16px;
    left: -8px;
    z-index: 0;
  }
  .appDownlod .storeIcon {
    height: 85px;
  }
  @media only screen and (max-width: 1400px) {
    .appDownlod .storeIcon {
      height: 75px;
    }
  }
  
  .collecTitle {
    color: #000;
    font-size: 18px;
    font-weight: 500;
  }
  
  
  .poupMd{max-width:372px;}
  .bogoPoup h3{font-size:16px; color:#000; font-weight:500; line-height:30px;}
  .ticketIcon h3{font-size:14px; font-weight:500; color:#000;}
  .ticketIcon .icon{font-size:22px;}
  
  
  
  /*=============== Edit Profile Page ===========================*/
  .editProfileBox h4 {
    font-size: 20px;
    color: #000;
    font-weight: 600;
    margin: 0;
    border-bottom: 5px solid #e9e9e9;
    padding-bottom: 16px;
  }
  .editImg figure {
    position: relative;
    bottom: -50px;
  }
  .editImg img {
    border-radius: 50%;
    margin-right: 15px;
    width: 150px;
    height: 150px;
    object-fit: cover;
  }
  .editImg figure a {
    position: absolute;
    width: 32px;
    height: 32px;
    border-radius: 50px;
    background: #0080fc;
    padding: 5px;
    color: #fff;
    text-align: center;
    line-height: 22px;
    font-size: 15px;
    display: inline-block;
    bottom: 8px;
    right: 22px;
    z-index: 1;
  }
  .formBox {
    background-color: #f5f6fa;
    border-radius: 10px;
    padding: 76px 30px 30px 30px;
  }
  .formBox input {
    border: 1px solid #e6ecf5;
    background: #fff;
    background-color: rgb(255, 255, 255);
    border-radius: 10px;
    font-size: 14px;
    height: auto;
    margin: 0;
    outline: 0;
    padding: 15px;
    padding-left: 15px;
    width: 100%;
    background-color: #ffffff;
    color: #8a97a0;
    position: relative;
    margin-bottom: 0;
    padding-left: 55px;
  }
  .formBox input:disabled {
    background: #eee;
  }
  .formBox .form-div {
    margin-bottom: 20px;
  }
  
  /*=============== Profile Ticket Detail Page ===========================*/
  .profileTicketDetail {
    padding-top: 70px;
  }
  .profileTicketBox {
    /*box-shadow:0 0 10px rgba(113, 113, 113, 0.16);*/
    padding: 0px 12px;
    background: #fff;
  }
  /* .profileTicketBox .bgChange{background-color:#D9D9D9;} */
  .profilTicket figure {
    padding: 10px;
    cursor: pointer;
    box-shadow: 0 3px 10px rgba(0, 0, 0, 0.3);
    background: #fff;
  }
  
  .profilTicket .row div[class*="col-"] {
    background: #ebebeb;
  }
  .profilTicket .row div[class*="col-"]:nth-child(even) {
    background: #d9d9d9;
  }
  
  .ticketContent {
    background-color: #fff;
    min-height: 450px;
  }
  .proTcktBox {
    position: relative;
  }
  .proTcktBox img {
    border-radius: 10px 10px 0 0;
  }
  .proTcktBox .overlay {
    background: rgba(0, 0, 0, 0.7);
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    border-radius: 10px 10px 0 0;
  }
  .proTcktBox .roundDate {
    height: 100px;
    width: 100px;
    background: #3c97f2;
    color: #fff;
    text-align: center;
    border-radius: 100%;
    border: 3px solid #fff;
    box-shadow: 0 0 5px #ddd;
    line-height: 16px;
    font-size: 16px;
    padding: 15px;
    margin-right: 35px;
  }
  .proTcktBox .roundDate span {
    color: #fff;
    font-size: 40px;
    display: block;
    font-weight: 600;
    line-height: 32px;
  }
  .tktDetail_2 p {
    font-size: 15px;
    color: #fff;
    margin-bottom: 0px;
  }
  .tktDetail_2 p.pfltxt {
    font-size: 14px;
  }
  .tktDetail_2 h5 {
    font-size: 16px;
    color: #fff;
    font-weight: 600;
    margin-bottom: 8px;
  }
  .tktDetail_2 h5 span {
    display: block;
    font-size: 15px;
    color: #fff;
    font-weight: 600;
    margin-top: 5px;
  }
  .storeTkt {
    background: #3c97f2;
    display: inline-block;
    color: #fff;
    padding: 5px 15px;
    font-size: 18px;
    font-weight: normal;
    position: absolute;
    bottom: 0;
    left: 0;
  }
  .storeTkt::after {
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 25px 38px 0px;
    border-color: transparent transparent #3c97f2 transparent;
    content: "";
    position: absolute;
    right: -25px;
    bottom: 0;
  }
  .viewVtn {
    background: #fff;
    color: #000;
    min-width: 137px;
    height: 48px;
    text-align: center;
    border-radius: 5px;
    font-size: 16px;
    font-weight: 500;
  }
  
  /*=============== Ticket Detail Page ===========================*/
  .ticktDisc_2 {
    background: #fff;
    border-radius: 10px;
    z-index: 1;
    padding: 20px;
    position: relative;
    margin-top: 10px;
  }
  .ticktDisc_2 h3 {
    color: #383f4e;
    font-size: 14px;
    font-weight: 500;
  }
  .ticktDisc_2 p {
    font-size: 14px;
    color: #000;
    font-weight: 400;
    line-height: inherit;
  }
  /*------- Detail Page New Design ---------*/
  /* .millonare {
    background: url(/public/images/betailBg.png) center center/cover;
  } */
  .millonare .overlay {
    background: transparent
      linear-gradient(180deg, #980a0a 0%, #000000 39%, #000000 71%, #000000 100%)
      0% 0% no-repeat padding-box;
    padding: 70px 0 90px;
    opacity: 0.84;
  }
  .millonare .overlay .container {
    opacity: 0.99;
  }
  .millonare .overlay p {
    text-align: center;
  }
  .millonare h3 {
    font-size: 24px;
    color: #fff;
    font-weight: 600;
    margin-bottom: 15px;
  }
  .millonare p {
    font-size: 15px;
    color: #fff;
    font-weight: normal;
    margin-bottom: 15px;
    line-height: 22px;
  }
  .notes {
    color: #fff;
    font-size: 14px;
    font-style: italic;
    font-weight: 300;
  }
  
  .excBenefit {
    background-color: #f4f5f9;
    padding-bottom: 70px;
  }
  .excButton {
    width: 400px;
    line-height: 80px;
    text-align: center;
    height: 80px;
    background: transparent linear-gradient(180deg, #64c4b1 0%, #3376c8 100%) 0%
      0% no-repeat padding-box;
    border-radius: 9px;
    font-size: 20px;
    font-weight: 600;
    color: #fff;
    position: relative;
    top: -40px;
  }
  .dayButton {
    width: 400px;
    line-height: 80px;
    text-align: center;
    height: 80px;
    background: transparent
      linear-gradient(180deg, #ee823a 0%, #ea3c63 51%, #5815b2 100%) 0% 0%
      no-repeat padding-box;
    border-radius: 9px;
    font-size: 20px;
    font-weight: 600;
    color: #fff;
    position: relative;
    top: -40px;
  }
  
  .excBenifitBox {
    background-color: #fff;
    border-bottom: 9px solid #3c97f2;
    padding: 32px;
    height: 180px;
    position: relative;
    margin: 70px 10px 0;
    flex: 0 0 48%;
  }
  
  .excBenifitBox h4 {
    font-size: 16px;
    font-weight: 600;
    color: #000;
    margin: 22px 0 5px;
  }
  .excBenifitBox p {
    font-size: 14px;
    font-weight: normal;
    color: #000;
    margin-bottom: 10px;
  }
  .excBenifitBox .excicon {
    width: 94px;
    height: 94px;
    border-radius: 50%;
    line-height: 94px;
    text-align: center;
    margin: 0 auto;
    position: relative;
    top: -50px;
    background: #fff;
    position: absolute;
    right: 0;
    left: 0;
  }
  .perksSection {
    position: relative;
    padding: 70px 0;
  }
  .perksSection:before {
    background: rgb(238, 130, 58);
    background: linear-gradient(
      180deg,
      rgba(238, 130, 58, 1) 0%,
      rgba(234, 60, 99, 1) 50%,
      rgba(88, 21, 178, 1) 100%
    );
    height: 278px;
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: -1;
  }
  .perksSection:after {
    /* background: url(/public/images/gift-bg.png) center center/cover; */
    height: 278px;
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
  }
  .perksSection h4 {
    font-size: 46px;
    font-weight: 600;
    color: #fff;
    text-align: center;
    margin-bottom: 50px;
  }
  .perksSection h4 span {
    display: block;
  }
  
  .borderRight .d-flex + .d-flex .rareIcon{position: relative;}
  .borderRight .d-flex + .d-flex .rareIcon::after{content:""; position:absolute; height:100px; width:1px; background:#bebebe; left:-26px; top:0px;}
  
  .rareBox {
    background: #fff;
    padding: 32px 32px 15px 32px;
    display: flex;
    border-bottom: 9px solid #b57f11;
    /* height: 212px; */
    position: relative;
  }
  
  .super_rareBox {
    border-bottom: 9px solid #e31a0c;
  }
  .rareBox .rareIcon {
    margin-right: 20px;
  }
  
  .rareBox h3{text-align: center; font-size:22px; font-weight:600; color:#000;}
  
  .rareBox h5 {
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 5px;
  }
  .rareBox p {
    font-size: 14px;
    font-weight: normal;
  }
  .rareDetail img {
    position: absolute;
    bottom: 0;
    right: 0;
    bottom: 10px;
  }
  
  .joinBox {
    background-color: #fff;
    border-radius: 20px;
    border: 8px solid #3c97f2;
    display: flex;
  }
  .joinBox .joinCnt {
    flex: 0 0 60%;
    padding: 20px;
    border-right: 1px dashed #9f9f9f;
    position: relative;
  }
  .joinBox .joinCnt:before,
  .joinBox .joinCnt:after {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    content: "";
    position: absolute;
    top: -8px;
    right: -16px;
    background-color: #3c97f2;
  }
  .joinBox .joinCnt:after {
    bottom: -8px;
    top: auto;
  }
  .joinBox .joinCnt h5 {
    font-size: 22px;
    font-weight: 600;
    color: #000;
    text-align: center;
    padding: 13px 58px;
    line-height: 35px;
  }
  .joinBox .joinBtn {
    flex: 0 0 35%;
    margin-left: 25px;
    text-align: center;
  }
  .joinBox .joinBtn p {
    color: #000;
    font-size: 26px;
    font-weight: 600;
    margin-bottom: 8px;
  }
  .joinBox .joinBtn .explorBtn {
    height: 54px;
    width: 160px;
    line-height: 52px;
    font-size: 18px;
    font-weight: 600;
    padding: 0;
  }
  .joinBox .joinBtn .explorBtn img {
    padding-left: 10px;
  }
  .multiUserTckt {
    background: #fff;
    padding: 32px;
    border-bottom: 9px solid #b57f11;
    position: relative;
  }
  .multiUserTckt h5 {
    font-size: 22px;
    font-weight: 600;
    text-align: center;
    margin-bottom: 45px;
  }
  .multiDetail h6 {
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 5px;
  }
  .multiDetail p {
    font-size: 14px;
    font-weight: normal;
    padding-right: 20px;
  }
  .multiUserTckt .text-end img {
    position: absolute;
    bottom: 0;
    right: 0;
    bottom: 10px;
  }
  .multiUserTckt .col-lg-6 + .col-lg-6 {
    border-left: 1px solid #ccc;
  }
  
  .popupTicketDetail .excButton,
  .popupTicketDetail .dayButton {
    width: 300px;
    line-height: 48px;
    height: 48px;
    font-size: 16px;
    top: 0;
  }
  .popupTicketDetail .excBenifitBox {
    background: #f4f5f9;
  }
  .popupTicketDetail .excBenifitBox .excicon {
    background: #f4f5f9;
  }
  .benTitle {
    position: relative;
  }
  .benTitle h3 {
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 20px;
    position: relative;
    margin-top: 20px;
  }
  .benTitle h3 span {
    display: inline-block;
    background-color: #fff;
    padding: 0 10px 0 0;
    position: relative;
    z-index: 1;
  }
  .benTitle h3:after {
    width: 100%;
    height: 1px;
    content: "";
    background-color: #c2c2c2;
    position: absolute;
    left: 0;
    bottom: 9px;
  }
  .profileTicketBox .profileTicketBox figure {
    padding: 0;
  }
  .profileTicketBox.popupTicketDetail .multiUserTckt {
    background: #f4f5f9;
  }
  .profileTicketBox.popupTicketDetail .multiUserTckt figure {
    padding: 0;
  }
  .congTitle {
    position: relative;
    padding-bottom: 50px;
  }
  .congTitle h5 {
    font-size: 46px;
    font-weight: 600;
    color: #fff;
    text-align: center;
    margin-bottom: 50px;
    position: relative;
    z-index: 0;
    padding-top: 40px;
  }
  .congTitle h5 span {
    display: block;
  }
  .congTitle::before {
    background: rgb(238, 130, 58);
    background: linear-gradient(
      180deg,
      rgba(238, 130, 58, 1) 0%,
      rgba(234, 60, 99, 1) 50%,
      rgba(88, 21, 178, 1) 100%
    );
    height: 278px;
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 0;
  }
  
  .congTitle:after {
    /* background: url(/public/images/gift-bg.png) center center/cover; */
    height: 278px;
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
  }
  
  .appDownlod .playStoreBoth .qrCodeDownload {
    display: flex;
    justify-content: space-around;
    text-align: center;
  }
  .appDownlod .playStoreBoth .qrCodeDownload figure {
    margin: 0 8px;
  }
  .appDownlod .playStoreBoth .qrCodeDownload img {
    width: 75px;
    padding: 4px;
    background: #fff;
    border-radius: 10px 0 10px 0;
  }
  .appDownlod .playStoreBoth .qrCodeDownload p {
    font-size: 14px;
    font-weight: 400;
  }
  
  /*=============== Edit Profile Page ===========================*/
  .editProfileBox h4 {
    font-size: 20px;
    color: #fff;
    font-weight: 600;
    margin: 0;
    border-bottom: 1px solid #d189ff;
    padding-bottom: 16px;
  }
  .editImg figure {
    position: relative;
    bottom: -50px;
  }
  .editImg img {
    border-radius: 50%;
    margin-right: 15px;
    width: 150px;
    height: 150px;
    object-fit: cover;
  }
  .editImg figure a {
    position: absolute;
    width: 32px;
    height: 32px;
    border-radius: 50px;
    background: #0080fc;
    padding: 5px;
    color: #fff;
    text-align: center;
    line-height: 22px;
    font-size: 15px;
    display: inline-block;
    bottom: 8px;
    right: 22px;
    z-index: 1;
  }
  .formBox {
    background-color:#162895;
    border-radius: 10px;
    padding: 76px 30px 30px 30px;
  }
  .formBox input {
    border: 1px solid #e6ecf5;
    background: #fff;
    background-color: rgb(255, 255, 255);
    border-radius: 10px;
    font-size: 14px;
    height: auto;
    margin: 0;
    outline: 0;
    padding: 15px;
    padding-left: 15px;
    width: 100%;
    background-color: #ffffff;
    color: #8a97a0;
    position: relative;
    margin-bottom: 0;
    padding-left: 55px;
  }
  .formBox input:disabled {
    background: #eee;
  }
  .formBox .form-div {
    margin-bottom: 20px;
  }
  
  /*=============== Profile Ticket Detail Page ===========================*/
  .profileTicketDetail {
    padding-top: 70px;
  }
  .profileTicketBox {
    /*box-shadow:0 0 10px rgba(113, 113, 113, 0.16);*/
    padding: 0px 12px;
    background: #fff;
  }
  /* .profileTicketBox .bgChange{background-color:#D9D9D9;} */
  .ticketContent_box figure {
    padding: 10px;
    cursor: pointer;
    box-shadow: 0 3px 10px rgba(0, 0, 0, 0.3);
    background: #fff;
  }
  
  .ticketContent_box .row div[class*="col-"] {
    background: #ebebeb;
  }
  .ticketContent_box .row div[class*="col-"]:nth-child(even) {
    background: #d9d9d9;
  }
  
  .ticketContent {
    background-color: #fff;
    min-height: 450px;
  }
  .proTcktBox {
    position: relative;
  }
  .proTcktBox img {
    border-radius: 10px 10px 0 0;
  }
  .proTcktBox .overlay {
    background: rgba(0, 0, 0, 0.7);
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    border-radius: 10px 10px 0 0;
  }
  .proTcktBox .roundDate {
    height: 100px;
    width: 100px;
    background: #3c97f2;
    color: #fff;
    text-align: center;
    border-radius: 100%;
    border: 3px solid #fff;
    box-shadow: 0 0 5px #ddd;
    line-height: 16px;
    font-size: 16px;
    padding: 15px;
    margin-right: 35px;
  }
  .proTcktBox .roundDate span {
    color: #fff;
    font-size: 40px;
    display: block;
    font-weight: 600;
    line-height: 32px;
  }
  .tktDetail_2 p {
    font-size: 15px;
    color: #fff;
    margin-bottom: 0px;
  }
  .tktDetail_2 p.pfltxt {
    font-size: 14px;
  }
  .tktDetail_2 h5 {
    font-size: 16px;
    color: #fff;
    font-weight: 600;
    margin-bottom: 8px;
  }
  .tktDetail_2 h5 span {
    display: block;
    font-size: 15px;
    color: #fff;
    font-weight: 600;
    margin-top: 5px;
  }
  
  .viewVtn {
    background: #fff;
    color: #000;
    min-width: 137px;
    height: 48px;
    text-align: center;
    border-radius: 5px;
    font-size: 16px;
    font-weight: 500;
  }
  
  /*=============== Ticket Detail Page ===========================*/
  .ticktDisc_2 {
    background: #fff;
    border-radius: 10px;
    z-index: 1;
    padding: 20px;
    position: relative;
    margin-top: 10px;
  }
  .ticktDisc_2 h3 {
    color: #383f4e;
    font-size: 14px;
    font-weight: 500;
  }
  .ticktDisc_2 p {
    font-size: 14px;
    color: #000;
    font-weight: 400;
    line-height: inherit;
  }
  /*------- Detail Page New Design ---------*/
 
  .millonare .overlay {
    background: transparent
      linear-gradient(180deg, #980a0a 0%, #000000 39%, #000000 71%, #000000 100%)
      0% 0% no-repeat padding-box;
    padding: 70px 0 90px;
    opacity: 0.84;
  }
  .millonare .overlay .container {
    opacity: 0.99;
  }
  .millonare .overlay p {
    text-align: center;
  }
  .millonare h3 {
    font-size: 24px;
    color: #fff;
    font-weight: 600;
    margin-bottom: 15px;
  }
  .millonare p {
    font-size: 15px;
    color: #fff;
    font-weight: normal;
    margin-bottom: 15px;
    line-height: 22px;
  }
  .notes {
    color: #fff;
    font-size: 14px;
    font-style: italic;
    font-weight: 300;
  }
  
  .excBenefit {
    background-color: #f4f5f9;
    padding-bottom: 70px;
    position: relative;
    z-index: 11;
  }
  
  .dayButton {
    width: 400px;
    line-height: 80px;
    text-align: center;
    height: 80px;
    background: transparent
      linear-gradient(180deg, #ee823a 0%, #ea3c63 51%, #5815b2 100%) 0% 0%
      no-repeat padding-box;
    border-radius: 9px;
    font-size: 20px;
    font-weight: 600;
    color: #fff;
    position: relative;
    top: -40px;
  }
  
  .excBenifitBox h4 {
    font-size: 16px;
    font-weight: 600;
    color: #000;
    margin: 22px 0 5px;
  }
  .excBenifitBox p {
    font-size: 14px;
    font-weight: normal;
    color: #000;
    margin-bottom: 10px;
  }
  .excBenifitBox .excicon {
    width: 94px;
    height: 94px;
    border-radius: 50%;
    line-height: 94px;
    text-align: center;
    margin: 0 auto;
    position: relative;
    top: -50px;
    background: #fff;
    position: absolute;
    right: 0;
    left: 0;
  }
  
  
  .rareBox h5,
  .rareBox h6 {
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 5px;
  }
  
  .rareBox p {
    font-size: 14px;
    font-weight: normal;
  }
  .rareDetail img {
    position: absolute;
    bottom: 0;
    right: 0;
    bottom: 10px;
  }
  
  .joinBox {
    background-color: #fff;
    border-radius: 20px;
    border: 8px solid #3c97f2;
    display: flex;
  }
  .joinBox .joinCnt {
    flex: 0 0 60%;
    padding: 20px;
    border-right: 1px dashed #9f9f9f;
    position: relative;
  }
  .joinBox .joinCnt:before,
  .joinBox .joinCnt:after {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    content: "";
    position: absolute;
    top: -8px;
    right: -16px;
    background-color: #3c97f2;
  }
  .joinBox .joinCnt:after {
    bottom: -8px;
    top: auto;
  }
  .joinBox .joinCnt h5 {
    font-size: 22px;
    font-weight: 600;
    color: #000;
    text-align: center;
    padding: 13px 58px;
    line-height: 35px;
  }
  .joinBox .joinBtn {
    flex: 0 0 35%;
    margin-left: 25px;
    text-align: center;
  }
  .joinBox .joinBtn p {
    color: #000;
    font-size: 26px;
    font-weight: 600;
    margin-bottom: 8px;
  }
  .joinBox .joinBtn .explorBtn {
    height: 54px;
    width: 160px;
    line-height: 52px;
    font-size: 18px;
    font-weight: 600;
    padding: 0;
  }
  .joinBox .joinBtn .explorBtn img {
    padding-left: 10px;
  }
  .multiUserTckt {
    background: #fff;
    padding: 32px;
    border-bottom: 9px solid #b57f11;
    position: relative;
  }
  .multiUserTckt h5 {
    font-size: 22px;
    font-weight: 600;
    text-align: center;
    margin-bottom: 45px;
  }
  .multiDetail h6 {
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 5px;
  }
  .multiDetail p {
    font-size: 14px;
    font-weight: normal;
    padding-right: 20px;
  }
  .multiUserTckt .text-end img {
    position: absolute;
    bottom: 0;
    right: 0;
    bottom: 10px;
  }
  .multiUserTckt .col-lg-6 + .col-lg-6 {
    border-left: 1px solid #ccc;
  }
  
  .popupTicketDetail .excButton,
  .popupTicketDetail .dayButton {
    width: 300px;
    line-height: 48px;
    height: 48px;
    font-size: 16px;
    top: 0;
  }
  .popupTicketDetail .excBenifitBox {
    background: #f4f5f9;
  }
  .popupTicketDetail .excBenifitBox .excicon {
    background: #f4f5f9;
  }
  .benTitle {
    position: relative;
  }
  .benTitle h3 {
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 20px;
    position: relative;
  }
  .benTitle h3 span {
    display: inline-block;
    background-color: #fff;
    padding: 0 10px 0 0;
    position: relative;
    z-index: 1;
  }
  .benTitle h3:after {
    width: 100%;
    height: 1px;
    content: "";
    background-color: #c2c2c2;
    position: absolute;
    left: 0;
    bottom: 9px;
  }
  .profileTicketBox .profileTicketBox figure {
    padding: 0;
  }
  .profileTicketBox.popupTicketDetail .multiUserTckt {
    background: #f4f5f9;
  }
  .profileTicketBox.popupTicketDetail .multiUserTckt figure {
    padding: 0;
  }
  
  /* .congTitle {
    position: relative;
    margin-bottom: 50px;
  }
  .congTitle h5 {
    font-size: 46px;
    font-weight: 600;
    color: #fff;
    text-align: center;
    margin-bottom: 50px;
    position: relative;
    z-index: 0;
    padding-top: 40px;
  }
  .congTitle h5 span {
    display: block;
  }
  .congTitle::before {
    background: rgb(238, 130, 58);
    background: linear-gradient(
      180deg,
      rgba(238, 130, 58, 1) 0%,
      rgba(234, 60, 99, 1) 50%,
      rgba(88, 21, 178, 1) 100%
    );
    height: 278px;
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 0;
  }
  
  .congTitle::after {
    background: url(http://localhost:3000/static/media/gift-bg.0ad82f41bd714b7187b5.png)
      center center/cover;
    height: 278px;
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
  } */
  
  .ListingTab {
    background: #fff;
    border-radius: 25px;
    padding: 5px;
    display: flex;
    justify-content: center;
    margin: 0 auto;
    width: 150px;
    height: 48px;
    margin-bottom: 20px;
  }
  .ListingTab li {
    height: 100%;
    width: 100%;
  }
  .ListingTab li button {
    padding: 5px 20px;
    font-size: 14px;
    font-weight: 500;
    color: #484747;
    width: 97%;
    border-radius: 25px;
    background: none;
    height: 100%;
    transition: all 0.5s;
  }
  .ListingTab li button.active,
  .ListingTab li button:hover {
    background-color: #000;
    color: #fff;
  }
  .rareImage {
    position: absolute;
    bottom: -5px;
    right: -5px;
    z-index: 1;
    height: auto !important;
    width: auto;
  }
  .put_on_sale {
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 1px solid #000;
    border-radius: 10px;
    margin-top: 20px;
    opacity: 1;
    color: #000;
    text-align: center;
    font-size: 16px;
    padding: 6px 15px;
    text-decoration: none;
    transition: all 0.5s;
    min-width: 124px;
  }
  .put_on_sale:hover {
    background: #000;
    color: #fff;
  }
  /*=============== Put on Sale popup ===========================*/
  .put_on_sale_popup .modal-dialog {
    max-width: 400px;
  }
  .put_on_sale_popup .login-screen .poupBox {
    padding: 5px;
  }
  .put_on_sale_popup .pop_content h3 {
    margin-bottom: 20px;
  }
  .desiAmount {
    padding: 10px 15px;
  }
  .desiAmount li {
    display: flex;
    justify-content: space-between;
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 8px;
  }
  .desiAmount li span {
    font-weight: 500;
    min-width: 82px;
  }
  .putRare {
    right: 13px;
  }
  .origanPrice {
    text-align: left;
  }
  .origanPrice h5 {
    font-size: 20px;
    font-weight: 600;
    /* color: #000; */
  }
  .origanPrice span {
    font-size: 14px;
    font-weight: normal;
    padding-bottom: 5px;
    color: #000;
  }
  
  .delistButton {
    padding: 0;
  }
  .delistButton button {
    padding: 0;
    background: #fff;
    border-color: #000;
    color: #000;
    font-size: 16px;
    font-weight: 500;
    opacity: 1;
    padding: 8px 15px;
    transition: all 0.5s;
    border: 1px solid #000;
    border-radius: 10px;
    min-width: 130px;
  }
  .delistButton button:hover {
    background-color: #000;
    color: #fff;
  }
  .delistButton button:last-child {
    background-color: #000;
    color: #fff;
  }
  .delistButton button:last-child:hover {
    background-color: #FFF;
    color: #000;
  }
  
  /*=============== Put on Sale popup ===========================*/
  
  /*=============== Ticket Detail Page ===========================*/
  /*=============== Not Found Page ===========================*/
  
  .notFound {
    padding: 0;
  }
  .notFound h2 {
    font-size: 26px;
    color: #000;
    font-weight: 600;
    margin-top: 30px;
  }
  .notFound p {
    font-size: 14px;
    font-weight: 500;
    margin-top: 10px;
  }
  .notFound button {
    width: 148px;
    height: 46px;
    color: #fff;
    background-color: #000;
    border-radius: 10px;
    text-align: center;
    font-size: 16px;
    font-weight: 500;
    margin-top: 25px;
    transition: all 0.5s;
  }
  .notFound button:hover {
    background-color: #fff;
    border: 1px solid #000;
    color: #000;
  }
  /*=============== Not Found Page ===========================*/
  
  /* ============== FOOTER ============== */
  
  footer {
    background: #000;
    color: #fff;
    padding: 30px 0 0;
  }
  footer .copyRight {
    text-align: center;
    padding: 25px;
    border-top: 1px solid #303134;
    margin-top: 25px;
    color: #afb2b8;
  }
  footer .copyRight a {
    color: #afb2b8;
    margin-bottom: 14px;
  }
  footer h3 {
    font-size: 16px;
    font-weight: 500;
  }
  footer .futLink {
    display: flex;
    justify-content: flex-end;
  }
  footer .futLink li + li {
    margin-left: 15px;
  }
  footer .futLink li a:hover {
    color: #bbb;
  }
  footer .socalMedia {
    display: flex;
  }
  footer .socalMedia li + li {
    margin-left: 10px;
  }
  footer .socalMedia a {
    background: #ededed;
    display: flex;
    height: 40px;
    width: 40px;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
  }
  
  .resendOtp button {
    background: none;
    text-decoration: underline;
    font-size: 14px;
    font-weight: normal;
  }
  .resendOtp span {
    font-size: 14px;
    font-weight: normal;
  }
  .barBg {
    padding: 0;
    position: relative;
  }
  .barBg img {
    margin: 0px 0 0 0;
    position: relative;
    right: 6px;
  }
  .imgBarCode {
    position: absolute;
    left: 0;
    top: 0;
    text-align: center;
    width: 91%;
  }
  .imgBarCode img {
    width: 70px;
    padding: 18px 0 0 0px;
    margin-left: 0px;
  }
  .imgBarCode p {
    font-size: 12px;
  }
  .tcktBarCode {
    margin: -70px 0 0 0;
    position: relative;
  }
  .backBtnAll {
    font-size: 14px;
    text-decoration: underline;
    background: none;
  }
  .popupTicketDetail {
    background: #fff;
    padding-bottom: 70px;
  }
  .availabeTitle h3 {
    font-size: 16px;
    font-weight: 600;
    color: #000;
    margin-bottom: 20px;
  }
  .slider2 img {filter: grayscale(100%);}
  .tcktImg img{height:127px; padding-top:5px;}
  .tcktImg .imgTickt{margin-top:5px; padding:10px; height:122px; background:#FFF; box-shadow:0 0 10px #666666;}
  
  
  /* ============== FOOTER ============== */
  .ListingSection {
    padding: 70px 0 100px;
  }
  .ListingSection h3 {
    font-size: 20px;
    font-weight: 600;
    color: #000;
    margin-bottom: 15px;
  }
  .tableBox {
    border-radius: 10px;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1);
    padding: 10px;
    background: #fff;
    max-height: 350px;
    overflow-y: scroll;
  }
  
  .tableBox table th {
    background-color: #f4f5f9;
  }
  .tableBox table td {
    background-color: #fff;
  }
  .tableBox table {
    border: none;
    border-collapse: collapse;
    margin: 0;
    padding: 0;
    width: 100%;
    table-layout: fixed;
  }
  .tableBox table caption {
    font-size: 1.5em;
    margin: 0.5em 0 0.75em;
  }
  .tableBox table tr {
    border-bottom: 2px solid #e5e5e5;
  }
  /* .tableBox table tr {background: #f8f8f8;border-bottom:1px solid #ccc;padding: .35em;} */
  /* .tableBox table tr th:first-child{border-radius:10px 0px 0px 0px;}
  .tableBox table tr th:last-child{border-radius:0px 10px 0px 5px;} */
  .tableBox table th,
  .tableBox table td {
    padding: 0.625em;
    text-align: left;
    font-size: 13px;
    font-weight: 500;
    color: #000;
  }
  .tableBox table td {
    padding: 12px;
  }
  .tableBox table th {
    font-size: 13px;
    font-weight: 500;
    color: #000;
  }
  .userList {
    padding: 0;
    text-align: left;
    display: flex;
  }
  .userList figure {
    margin-right: 10px;
  }
  .userList figure,
  .userList figure img {
    width: 35px;
    height: 35px;
    border-radius: 50%;
  }
  .userList h6 {
    font-size: 13px;
    font-weight: 500;
    color: #000;
  }
  .userList p {
    color: #98999d;
    font-size: 12px;
    font-weight: normal;
  }
  .tableBox .listBuyBtn {
    width: 65px;
    height: 24px;
    line-height: 20px;
    font-size: 12px;
    color: #fff;
    font-weight: 600;
    border-radius: 5px;
    background: #000;
  }
  @media screen and (max-width: 767px) {
    .tableBox table {
      border: 0;
    }
    .tableBox table caption {
      font-size: 1.3em;
    }
    .tableBox table thead {
      border: none;
      clip: rect(0 0 0 0);
      height: 1px;
      margin: -1px;
      overflow: hidden;
      padding: 0;
      position: absolute;
      width: 1px;
      color: red;
      background-color: #000;
    }
    .tableBox table tr {
      border-bottom: 3px solid #ddd;
      display: block;
      margin-bottom: 0.625em;
    }
    .tableBox table td {
      border-bottom: 1px solid #ddd;
      display: block;
      font-size: 0.8em;
      text-align: right;
    }
    .tableBox table td:before {
      content: attr(data-label);
      float: left;
      font-weight: bold;
      text-transform: uppercase;
    }
    .tableBox table td:last-child {
      border-bottom: 0;
    }
    .tableBox table td:first-child {
      color: #000;
      background: #fff;
    }
    .userList {
      justify-content: end;
    }
    .tableBox .listBuyBtn {
      display: flex;
      text-align: center;
      justify-content: center;
      margin: 0 auto;
    }
  }
  
  /*=============== Profile Page ===========================*/
/*-------------- Face and Google Btn -----------*/
  .socialMediaBtn button{ padding:5px 8px; border-radius: 5px; font-size: 13px; font-weight: 500;color: #fff; display: flex; align-items: center;}
  .socialMediaBtn button i{font-size: 18px; margin-right: 6px;}
  .socialMediaBtn button.googleBtn{background:#ea4335 ;}
  .socialMediaBtn button.facebookBtn{background:#4867aa;}
  .socialMediaBtn button .metro {background: none;border: none;text-transform: capitalize;font-size: 13px;font-weight:500;font-family: 'Montserrat', sans-serif !important;}
  .orTitle{color: #fff; display: flex; align-items: center; justify-content: center; margin: 20px 0;}
  .orTitle span{display: inline-block; padding: 0 15px; font-weight: 500;}
  .orTitle:after{width: 50px; height: 1px; background: #fff;content: ""; display: inline-block;}
  .orTitle:before{width: 50px; height: 1px; background: #fff;content: ""; display: inline-block;}
/*-------------- Face and Google Btn -----------*/


  /*=============== Buy Popup ===========================*/
  span.text-danger {color: #ffe40c !important;font-weight: 500;}
  .inputWithIcon ul{margin: 0; padding: 0;}
  .inputWithIcon ul li.text-danger {color: #ffe40c !important;font-weight: 500;}


  .coupnapply.slidDown{top:100px !important; transition: all 2s;}
  
  .specalCoupan{margin-top:90px;}
  
  
  .couponCode{border: 1px solid #ccc;border-radius: 10px;padding: 5px;background: #fff; width: 100%;}
  .couponCode .form-control{border:0px; padding:0 10px;}
  .couponCode button.btn:disabled{background:#B4B3B3;}
  .couponCode button.btn{background:#000; border-radius:6px;color: #fff;text-align: center;font-size: 13px;font-weight: 600;max-width:70px;min-height: 31px;transition: all 0.5s;}
  .couponCode button:hover{background-color: #000;color: #fff;}
  .coupnapply{width:100%;background: transparent linear-gradient(90deg, #E23588 0%, #4516C1 100%) 0% 0% no-repeat padding-box;border-radius: 10px; position: absolute;top:70px;color: #fff;font-size: 15px;font-weight: 500; padding: 0 15px 10px 15px;}
  .coupnapply figure{margin-top: -15px; padding-bottom: 5px;}
  .starGraphic {position: absolute;top: -38px;left: 0;right: 0;}
  .deducet{font-size: 13px;color: #FF0000;list-style:28px; margin-top: 20px;font-weight: normal;}
  
  .availableAmout{background: transparent linear-gradient(270deg, #EE823A 0%, #EA3C63 48%, #5815B2 100%) 0% 0% no-repeat padding-box;border: 2px solid #FFFFFF;border-radius: 9px;}
  .availableAmout span{display: inline-block;width: 80px;font-size: 15px;font-weight: 500;height: 32px;background: #FFFFFF 0% 0% no-repeat padding-box;box-shadow: 0px 3px 6px #00000029;border-radius: 6px;text-align: center;line-height: 32px;}
  .availableAmout div{font-size: 16px;font-weight: 500; color: #fff;}
  .availableAmout div svg{font-size:20px;font-weight: 500; color: #fff; margin-right:10px;}
  .withdrawTable{background: #FFFFFF 0% 0% no-repeat padding-box;box-shadow: 0px 0px 10px #0000001A;border: 1px solid #C8C8C8;border-radius: 10px; width: 100%;margin-bottom:30px; padding: 15px;}
  .withdrawTable h3{font-size: 18px;font-weight:600;color: #000; margin-bottom: 10px;}
  
  .withdrawTable ul li{color: #474747;font-size: 13px;font-weight: normal; padding-bottom: 8px;}
  .withdrawTable ul li span{color: #000;font-size: 13px;font-weight: 600;}
  .withdrawTable .listPrice li{display: flex; justify-content: space-between;}
  
  .recieveAmout{position: relative; padding-left: 30px;}
  .recieveAmout h5{font-size: 14px;font-weight: 500;color: #000; padding-bottom: 8px;}
  .recieveAmout p{font-size: 16px;font-weight: 600;color: #000;}
  .recieveAmout::before{height: 116px;width: 1px;content: "";border-left: 2px dashed #D2D2D2;position: absolute;left: -34px;margin: -48px 0 0;}
  .supRare {position: absolute;right: -15px;top: 0;}
  
  .avaAmout h5{font-size: 14px;font-weight: 500;color: #000; padding-bottom: 8px;}
  .avaAmout p{font-size: 16px;font-weight: 600;color: #000;}
  .avaAmoutMain button {width: 215px;height: 48px;background: #000000 0% 0% no-repeat padding-box;border-radius: 10px;color: #fff;font-size: 16px;font-weight: 600;margin-left: 30px;}
  .avaAmoutMain button svg{margin-left: 10px;}
  .avaAmoutMain {display: flex;justify-content: end;border-bottom: 2px solid #ccc;border-top: 2px solid #ccc;padding: 29px 0;}
  .availableAmout.availableAmout_2 {width:340px;margin:-12px auto 10px;position: relative;}
  
  .listTitle{font-size:23px;font-weight:500; margin-bottom:20px;}
  .viewBtn{background: #000 0% 0% no-repeat padding-box;border: 1px solid #000;border-radius: 10px;margin-top: 20px;color: #fff;text-align: center;font-size: 16px;padding: 6px 15px;text-decoration: none;transition: all 0.5s;min-width: 124px;}
  .viewBtn:hover{background: #ffffff 0% 0% no-repeat padding-box;color: #000;}
  
  /*=============== Responsive ===========================*/
  @media only screen and (min-width: 1200px) {
    .ticktDisc_2 {
      display: none;
    }
  }
  @media only screen and (max-width: 1440px) {
    .marquee-item {
      flex: 0 0 20vw;
    }
  
  }
  
  @media only screen and (max-width: 1399px) {
    
    .homeBanner .offer{width:160px; bottom:-45px; right:20%;}
  
  }
  
  
  @media only screen and (max-width: 1199px) {
  
    .borderRight .d-flex + .d-flex .rareIcon::after {display:none;}
  
    .hideDiv {
      display: none;
    }
    .ticktDisc_2 {
      display: block;
    }
    /* .circle_2{right: 194px;} */
    .explorBtn {
      padding: 8px 18px;
    }
    .ticketBox .pricBtn .price {
      margin: 0 38px 0 0;
    }
    /* .ticketBox img.ticketImg { width: 230px;height: 185px;} */
    .ticketBox {
      display: block;
    }
    .appDownlod {
      margin: 200px 0 100px;
    }
    .appDownlod .webMobile {
      left: 70px;
    }
    .appDownlod .playStoreBoth .qrCodeDownload {
      justify-content: center;
      margin-bottom: 20px;
    }
    .marquee-item {
      flex: 0 0 25vw;
    }
    .tcktBarCode {
      margin-top: 30px;
    }
    .tcktImg img {
      padding-top: 4px;
      height: 128px;
      object-fit: cover;
    }
    .appDownlod .playStoreBoth .qrCodeDownload {
      justify-content: center;
      margin-bottom: 20px;
    }
    .appDownlod .webMobile {
      left: 70px;
    }
    .ticketFeatr {
      padding: 0 0 24px;
    }
    .pricBtn ul li:nth-child(2) {
      margin-right: 12px;
    }
    .profileTicketBox.popupTicketDetail .multiUserTckt figure img {
      width: 70px;
      height: auto;
    }
    .appDownlod {
      text-align: center;
      margin: 170px 0 50px;
    }
    .excBenifitBox {
      flex: 0 0 47%;
      margin-right: 10px;
    }
  }
  
  @media only screen and (max-width: 991px) {
  
    .homeBanner .offer{width:130px; bottom:-40px; right:15%;}
    .hideDiv{display:block;}
    .guestTicket .guestDetail{position:relative;}
    .futrEvent.celebrityGuests .contBox {left: auto; padding: 20px;}
    .guestTicket .guestDetail .logo{width: 240px;}
    .guestTicket .guestDetail .gestImg{width:150px;}
  
  
    .ticktDisc_2 {
      display: none;
    }
    .contBox {
      position: relative;
      top: auto;
      width: auto;
      right: auto;
      margin-bottom: 20px;
      padding: 0px;
      border-radius: 0 0 20px 20px;
    }
    .futrEvent .ftrImg {
      border-radius: 20px 20px 0 0;
      width: 100%;
      height: auto;
    }
    .textCont p {
      font-size: 15px;
    }
  
    .ticketBox {
      flex-wrap: wrap;
    }
    .ticketBox figure {
      width: 100%;
    }
  
    .tktDetail .tktbgImages {
      display: none;
    }
  
    .tktDetail .ticketBox img.ticketImg {
      width: 100%;
      /* height: 200px; */
    }
    /* .ticketBox img.ticketImg {
      width: 100%;
      height: 300px;
    } */
    .ticketBox .textCont {
      padding: 20px;
      flex: auto;
    }
    /* 
    .ticketBox .pricBtn {
      padding: 20px;
      width: 100%;
      border-top: 2px dashed #ccc;
    } */
    .ticketBox .pricBtn .explorBtn {
      width: 100%;
    }
    .ticketBox .pricBtn .explorBtn img {
      padding-left: 20px;
    }
    .ticketBox .pricBtn ul {
      text-align: center;
      display: flex;
      align-items: center;
    }
    .ticketBox .pricBtn ul li:last-child {
      width: 100%;
      text-align: center;
      margin-top: 0px;
    }
  
    .tktDetail .ticketBox .textCont {
      flex: 1 0 70%;
    }
  
    .benefits ul li + li {
      margin-left: 10px;
    }
  
    /* .appDownlod{text-align:center; margin:0 0 50px; padding:50px 30px 30px;}
  
    .appDownlod .webMobile {
      position: relative;
      top: auto;
      left: auto;
      margin-bottom:50px;
      display: inline-block;
    } */
  
    .appDownlod {
      text-align: center;
      margin: 350px 0 50px;
      padding: 300px 30px 30px;
    }
  
    .appDownlod h3 {
      margin-bottom: 20px;
    }
  
    .appDownlod .webMobile {
      bottom: 340px;
      top: auto;
      left: 50%;
      margin-bottom: 50px;
      display: inline-block;
      margin-left: -148px;
    }
  
    footer .socalMedia {
      justify-content: center;
      margin-top: 20px;
    }
    footer .futLink {
      justify-content: center;
      flex-wrap: wrap;
    }
    footer .futLink li {
      margin: 5px 15px;
    }
    footer h3 {
      text-align: center;
    }
  
    .appDownlod:before {
      left: 50px;
      bottom: 45%;
    }
    .cardBox .viewBtn_2 a,
    .cardBox .viewBtn_2 a.ViewDetail {
      width: 130px;
    }
    .marquee-item {
      flex: 0 0 33vw;
    }
    .graphic .graImg_1,
    .graphic .graImg {
      display: none;
    }
    .luckyBox_1 {
      margin-bottom: 38px;
    }
    .proTcktBox img {
      height: 250px;
      object-fit: cover;
    }
    .proTcktBox .roundDate {
      margin-right: 13px;
      flex: 0 0 auto;
    }
    .profileTicketBox figure {
      padding: 10px 0;
    }
    .ticketBox.ticketBoxDetail figure {
      width: 100%;
    }
    .circle,
    .circle_2 {
      display: none;
    }
    .contBox .shareBtn {
      top: 15px;
    }
    .contBox .roundDate {
      /*position:relative; top:auto; right:auto; margin:auto;*/
      bottom: 252px;
      top: auto;
    }
    .contBox.textCont p {
      font-size: 14px;
      font-weight: 400;
    }
    .ticketBoxDetail.ticketBox .pricBtn ul {
      display: inherit;
      width: 100%;
      padding: 14px 20px;
      border-top: 2px dashed #9f9f9f;
    }
    .ticketBoxDetail.ticketBox .pricBtn {
      flex: 0 0 100%;
    }
    .ticketBox .textCont .ticketDate.ticketDateDetail::after {
      display: none;
    }
    .ticketBoxDetail.ticketBox .textCont h3 {
      padding-right: 30px;
    }
    .ticketBoxDetail.ticketBox .textCont {
      padding: 20px;
    }
    /* .tktDetail {
      padding: 20px 0;
      background: url(/public/images/ticket-detail-bg.png) no-repeat center center;
      background-size: cover;
    } */
  
    .tktDetail .container {
      position: relative;
      top: auto;
      left: auto;
      transform: none;
    }
    
    .excBenifitBox{text-align: center;height: auto;}
    .rareBox{margin-bottom: 20px;}
    
  
    .excButton,.dayButton{width: 100%;font-size: 17px;}
    .multiUserTckt .col-lg-6 + .col-lg-6 {border-top: 1px solid #ccc;padding-top: 20px; border-left: none;margin-top: 20px;}
    .congTitle::before{width: 100%;height: 200px;left: 0;}
    .congTitle h5 {font-size:26px;padding-left: 15px;padding-right: 15px;}
    .tcktBarCode{margin-top: 20px;}
    .imgBarCode{position: relative; width: 100%;}
    .barBg {position: relative;box-shadow: 0 0 5px #ccc;border-radius: 0 0 10px 10px;margin-bottom: 30px;padding: 0 0 10px 0;}
    .imgBarCode img{width: 90px;}
    .profileTicketBox.popupTicketDetail .multiUserTckt figure{padding-bottom: 15px;}
    .profileTicketBox.popupTicketDetail .multiUserTckt figure img{width: 50px; height: auto;}
    .multiDetail p{padding-right: 0;}
    .multiUserTckt .col-lg-6{padding-left: 0; padding-right: 0;}
    .tcktImg img {padding-top: 0;height: auto;object-fit: inherit;border-radius: 10px 10px 0 0;}
    .excButton, .dayButton{height: 60px; line-height: 60px;}
    .rareBox .rareIcon img{width: 50px; height: auto;}
    .appDownlod {text-align: center;margin: 350px 0 50px;padding: 300px 30px 30px;}
    .appDownlod .webMobile {bottom: 340px;top: auto;left: 50%;margin-bottom: 50px;display: inline-block;margin-left: -148px;}
    .ticketType, .listPrice {border-bottom: 3px solid #ccc;margin-bottom: 13px;}
    .recieveAmout{padding-left: 0;}
    .recieveAmout::before{display: none;}
    
   
    .excButton,
    .dayButton {
      width: 100%;
      font-size: 17px;
    }
    .multiUserTckt .col-lg-6 + .col-lg-6 {
      border-top: 1px solid #ccc;
      padding-top: 20px;
      border-left: none;
      margin-top: 20px;
    }
    .congTitle::before {
      width: 100%;
      height: 200px;
      left: 0;
    }
    .congTitle h5 {
      font-size: 26px;
      padding-left: 15px;
      padding-right: 15px;
    }
    .tcktBarCode {
      margin-top: 20px;
    }
    .imgBarCode {
      position: relative;
      width: 100%;
    }
    .barBg {
      position: relative;
      box-shadow: 0 0 5px #ccc;
      border-radius: 0 0 10px 10px;
      margin-bottom: 30px;
      padding: 0 0 10px 0;
    }
    .imgBarCode img {
      width: 90px;
    }
    .profileTicketBox.popupTicketDetail .multiUserTckt figure {
      padding-bottom: 15px;
    }
    .profileTicketBox.popupTicketDetail .multiUserTckt figure img {
      width: 50px;
      height: auto;
    }
    .multiDetail p {
      padding-right: 0;
    }
    .multiUserTckt .col-lg-6 {
      padding-left: 0;
      padding-right: 0;
    }
    .tcktImg img {
      padding-top: 0;
      height: auto;
      object-fit: inherit;
      border-radius: 10px 10px 0 0;
    }
    .excButton,
    .dayButton {
      height: 60px;
      line-height: 60px;
    }
    .rareBox .rareIcon img {
      width: 50px;
      height: auto;
    }
    .appDownlod {
      text-align: center;
      margin: 350px 0 50px;
      padding: 300px 30px 30px;
    }
    .appDownlod .webMobile {
      bottom: 340px;
      top: auto;
      left: 50%;
      margin-bottom: 50px;
      display: inline-block;
      margin-left: -148px;
    }
  }
  
  @media only screen and (max-width: 767px) {
    
    .homeBanner .offer{width:110px; bottom:-30px; right:15%;}
    
    /* .ticketBox img.ticketImg {
      height: 150px;
    } */
  
    .ticketBox {
      display: block;
    }
    .pflLoginBtn .bothBtn {
      padding: 5px 7px;
    }
    .perkSectn div[class*="col-"]:nth-child(2) p {
      background: #3c97f2;
      color: #fff;
    }
  
    .appDownlod h3 {
      font-size: 22px;
    }
  
    .perkSectn div[class*="col-"]:nth-child(3) p {
      background: #3c97f2;
      color: #fff;
    }
    .perkSectn div[class*="col-"]:nth-child(6) p {
      background: #3c97f2;
      color: #fff;
    }
    .perkSectn div[class*="col-"]:nth-child(7) p {
      background: #3c97f2;
      color: #fff;
    }
  
    .contBox {
      padding: 0px;
    }
  
    /** profile page **/
    .profileBox {
      padding: 20px;
    }
    .cardBox {
      margin-bottom: 30px;
    }
    .popup_close {
      right: 0;
      top: 0;
      background: #000;
      width: 40px;
      height: 40px;
      z-index: 10001;
    }
    .userProfile i {
      margin: 0;
    }
    /* .ticketBox .textCont .ticketDate {
      top: -90px;
    } */
    /* .tktDetail .ticketBox img.ticketImg{height: 250px; object-fit: cover; width: 100%;} */
    /** profile page **/
    .marquee-item {
      flex: 0 0 50vw;
    }
    .textCont p {
      font-size: 14px;
      color: #6c6c6c;
      font-weight: 400;
    }
    .proTcktBox .overlay {
      padding: 20px;
      text-align: center;
    }
    .proTcktBox img {
      height: 300px;
      object-fit: cover;
    }
    .proTcktBox .roundDate {
      flex: 0 0 auto;
      margin: 0 auto 15px;
    }
    .storeTkt {
      font-size: 15px;
    }
    .storeTkt::after {
      border-width: 0 25px 32px 0px;
    }
    .viewVtn {
      margin-bottom: 20px;
      height: 40px;
    }
  
    .profileTicketDetail {
      padding-top: 25px;
    }
    .tktDetail_2 {
      width: 100%;
    }
    .ticketBox.ticketBoxDetail figure {
      width: 100%;
    }
    .circle,
    .circle_2 {
      display: none;
    }
    .tktDetail .ticketBox .shareBtn {
      top: 13px;
      right: 12px;
    }
    h3.titl {
      font-weight: 500;
    }
    .textCont h4 {
      font-weight: 500;
    }
    .dayButton {
      top: 0;
      margin-top: 20px !important;
    }
    .perksSection h4 {
      font-size: 30px;
      margin-bottom: 20px;
    }
  
    .excBenifitBox{flex:0 0 100%;}
  
    .rareBox {
      height: auto; /*display: block;*/
      margin-bottom: 20px;
      padding: 19px 18px 30px 16px;
    }
    .perksSection {
      padding: 30px 0;
    }
    .perksSection::before,
    .perksSection::after {
      height: 200px;
    }
    .rareBox .rareIcon {
      margin-bottom: 20px;
    }
    .joinBox {
      display: block;
    }
    .joinBox .joinCnt {
      border-bottom: 1px dashed #9f9f9f;
    }
    .joinBox .joinCnt h5 {
      padding: 13px 30px;
    }
    .joinBox .joinBtn {
      padding: 20px;
      text-align: center;
      margin: 0;
    }
    .joinBox .joinBtn button {
      margin-top: 10px;
    }
    .joinBox .joinCnt::after {
      bottom: -16px;
      top: auto;
      left: -8px;
    }
    .joinBox .joinCnt::before {
      bottom: -16px;
      right: -8px;
      top: auto;
    }
    .congTitle h5 {
      font-size: 22px;
    }
    .appDownlod h3 {
      margin-bottom: 15px;
      font-size: 16px;
    }
  
  }
  @media only screen and (max-width: 575px) {
  
  
    .homeBanner .offer{width:100px;bottom:-20px;right:10%;}
  
    .appDownlod .webMobile {
      bottom: 430px;
    }
  
    .appDownlod .playStoreBoth img {
      width: 100%;
    }
    .marquee-item {
      flex: 0 0 50vw;
    }
    .ticketBox .textCont .ticketDate .roundDate span {
      font-size: 28px;
      line-height: 29px;
    }
    .ticketBox .textCont .ticketDate .roundDate {
      font-size: 14px;
    }
  
    .proTcktBox .roundDate {
      height: 80px;
      width: 80px;
      font-size: 12px;
    }
    .proTcktBox .roundDate span {
      font-size: 30px;
      line-height: 16px;
      display: block;
      margin-bottom: 5px;
    }
    .appDownlod h3 {
      font-size: 22px;
    }
    /* .appDownlod .webMobile{bottom:200px;} */
  
    .pflHeader .topLogo {
      justify-content: center;
    }
    .pflHeader .topLogo img {
      max-width: 160px;
    }
    .pflLoginBtn {
      text-align: center;
      margin-top: 16px;
    }
    .countryNumber .css-13cymwt-control span.small,
    .countryNumber .css-t3ipsp-control span.small {
      width: 106px;
    }
    .appDownlod .webMobile {
      bottom: 430px;
    }
    .explorBtn {
      padding: 8px 14px;
    }
  
    .pflHeader .topLogo {
      justify-content: center;
    }
    .pflHeader .topLogo img {
      max-width: 160px;
    }
    .pflLoginBtn {
      text-align: center;
      margin-top: 16px;
    }
    .countryNumber .css-13cymwt-control span.small,
    .countryNumber .css-t3ipsp-control span.small {
      width: 106px;
    }
    .appDownlod .webMobile {
      bottom: 340px;
    }
    
  
  }
  
  @media only screen and (max-width: 475px) {
  
    .pflLoginBtn .bothBtn {
      font-size: 12px;
    }
    footer .socalMedia li + li {
      margin-left: 6px;
    }
    footer .socalMedia a {
      height: 38px;
      width: 38px;
    }
    /* .appDownlod{padding:30px 20px;} */
    h3.titl {
      font-size: 22px;
    }
    .ticketBox .textCont {
      padding: 10px;
    }
    .login-screen .poupBox {
      padding: 15px;
    }
    .appDownlod h3 {
      font-size: 18px;
    }
    .excButton,
    .dayButton {
      width: 100%;
      font-size: 18px;
      margin-top: 30px !important;
    }
  }
  
  @media only screen and (max-width: 465px) {
    .appDownlod .webMobile {
      bottom: 400px;
    }
  }
  
  @media only screen and (max-width: 380px) {
  
    .guestTicket .guestDetail .logo{width:200px;}
    .guestTicket .guestDetail p{font-size:16px;}
    .guestTicket .guestDetail .gestImg{width:100px;}
  
  
    .homeBanner .offer{width:70px; right:16%; filter: drop-shadow(0px 0px 2px #444);}
  
    footer .futLink a {
      font-size: 13px;
    }
    footer .socalMedia {
      margin-top: 15px;
    }
    footer .socalMedia a {
      height: 35px;
      width: 35px;
      border-radius: 5px;
    }
    footer .copyRight {
      padding: 20px;
      margin-top: 25px;
      font-size: 14px;
    }
    footer .socalMedia li + li {
      margin-left: 10px;
    }
  
    .pflHeader .topLogo img {
      max-width: 140px;
    }
    .pflHeader .topLogo a:first-child {
      margin-right: 10px;
      padding-right: 10px;
    }
    .pflHeader .topLogo a:first-child::after {
      height: 25px;
      top: 0px;
      width: 1px;
    }
  
    .appDownlod {
      margin: 240px 0 50px;
      padding: 300px 30px 30px;
    }
    .appDownlod h3 {
      margin-bottom: 15px;
      font-size: 16px;
    }
    .appDownlod .webMobile {
      bottom: 415px;
    }
    .appDownlod .webMobile:after {
      width: 260px;
      height: 505px;
      background-size: 100% 100%;
    }
    .appDownlod .webMobile {
      width: 245px;
      margin-left: -122px;
    }
  
    .countryNumber .css-13cymwt-control .css-1fdsijx-ValueContainer,
    .countryNumber .css-t3ipsp-control .css-1fdsijx-ValueContainer {
      padding: 2px;
    }
    .countryNumber .countryCode__menu {
      z-index: 9;
      width: 230px;
    }
    .check-box-div span {
      width: 80%;
    }
  
    .ticketBox .textCont .ticketDate .roundDate {
      top: -115px;
    }
    .appDownlod {
      margin: 240px 0 50px;
      padding: 300px 30px 30px;
    }
    .appDownlod .webMobile {
      bottom: 415px;
    }
  }
  

  /*------------------- Custom CSS 11-07-2023 ------------------------*/
  .menuSectionLeft{/*background-color:#1B3CB7;*/background: rgb(93,93,93);background: linear-gradient(180deg, rgba(93,93,93,1) 0%, rgba(33,33,33,1) 100%); min-height:100vh;}
  .userImage{border-radius: 50%; text-align: center; margin-bottom:30px;}
  .userImage img{border-radius: 50%; width:116px; display: inline-block;height: 116px;object-fit: cover;margin-top: 25px;}
  .userImage a{display: inline-block;background-color: #000;color: #fff;border-radius: 5px; text-decoration: none; padding: 4px 24px; margin-top:10px;}
  .userImage figure{margin: 0;}
  .product{border-top: 1px solid #9F9F9F;padding:40px 30px;}
  .product h3{font-size: 20px;font-weight: 600;color: #fff; margin-bottom:20px;}
  .product ul{padding: 0;margin: 0;}
  .product ul li{margin-bottom:16px; padding: 4px 5px;}
  .product ul li a img{transition: all 0.35s; width: 22px;}
  .product ul li a span {width:35px;height:35px;display: inline-block;border-radius: 50%;background:#3555ce;text-align: center;line-height:33px;margin-right:10px;}
  .product ul li.active{color:#fff;background-color:#000;border-radius: 5px; }
  .product ul li.active a span{background-color: #000;}
  .product ul li a{color: #fff; font-size: 16px;text-decoration: none;font-weight: 600;}
  .product ul li a:hover{color:#fff ;}
  /* .product ul li a:hover img{ margin-right: 20px;} */

  .dashboardBtn a{background-color: #000;color: #fff;border-radius: 5px; padding: 6px 7px; width: 100%; display: block;text-decoration: none;font-weight: 600;font-size:18px;}
  .dashboardBtn a span{width: 33px;height: 33px;background-color: #fff;border-radius: 50%;display: inline-block; text-align: center; line-height: 33px; margin-right: 15px;}
  .createAi{height:40px;background: rgb(188,61,240);background: linear-gradient(-90deg, rgba(188,61,240,1) 0%, rgba(27,60,183,1) 100%);border-radius: 5px;min-width:170px; /*margin-top: 8px;*/font-weight: 600;font-size:16px; line-height: 40px;color: #fff;padding: 0 12px;position: relative;display: flex;text-decoration: none;align-items: center;}
  .createAi i{font-style: normal; padding-left: 5px;}
  .createAi img{margin-right: 10px;}
  .createAi span{background-color: #fff;color: #000; display: inline-block;border-radius: 5px;width: 25px;height: 25px; text-align: center;line-height: 25px;font-size: 14px;position: absolute;right: 10px;top: 7px;}
  .createAi:hover{color: #fff;}
  .orgBtn{color: #fff;}
  .tabing li.orgBtn .orgName {border-radius: 50px;border: 0.5px solid #FFDA7A;width:auto;height: 40px;font-size: 16px;line-height: 33px;text-align: left;padding:0px 25px; text-align: center; line-height: 40px;}
    .tabing li.orgBtn .personaName  {font-size: 16px; margin-left:15px; border-radius: 25px; height: 40px; line-height: 33px; background: #fff; padding: 3px 15px 3px 3px; font-weight: 500; color: #000;}
    .tabing li.orgBtn .personaName  img{width:35px;border-radius: 50%; margin-right: 5px;}



  /* .createAi{height:45px;background: transparent linear-gradient(289deg, #62B833 0%, #1B3CB7 100%) 0% 0%;border-radius: 5px;width: 100%; margin-top: 8px;font-weight: 600;font-size:18px; line-height: 40px;color: #fff;padding: 0 12px;position: relative;}
  .createAi img{margin-right: 15px;}
  .createAi span{background-color: #fff;color: #000; display: inline-block;border-radius: 5px;width: 25px;height: 25px; text-align: center;line-height: 25px;font-size: 14px;position: absolute;right: 10px;top: 10px;}
   */
  
  .tabing{display: flex; border-bottom: 5px solid #414141; align-items: center; overflow-x:auto;}
  .tabing li button{border-radius: 5px; font-size:14px; color: #9E9E9E; font-weight: 600; padding: 5px 8px; text-align: left;transition: all 0.5s;background: none;display: flex;align-items: center; white-space:nowrap;}
  .tabing li button svg{margin-right: 8px;font-size: 16px;}
  .tabing li button:hover{background-color: #516FDF;color: #fff;}
  .tabing li button.active{background-color: #516FDF;color: #fff;}
  .refernceAudio{padding: 40px;border-radius: 15px; /*background-color: #F3A76D;*/background: rgb(188,61,240);background: linear-gradient(180deg, rgba(188,61,240,1) 0%, rgba(27,60,183,1) 100%);}
  .refernceAudio p{color: #fff;}
  .refernceAudio:empty{display:none;}

  .refernceAudio input{ height: 56px;border-radius: 5px;border:none;background: #fff;}
  .refernceAudio select{ height: 56px;border-radius: 5px;border:none; background: url(/public/images/arrowDown.png) no-repeat right center #fff; }
  .refernceAudio textarea{ height:156px;border-radius: 5px;border: 1px solid #ccc;}
  .refernceAudio input:focus,.refernceAudio select:focus,.refernceAudio textarea:focus{box-shadow: none;}
  .refernceAudio label{font-size:16px; font-weight:500; margin-bottom: 10px; display:block;color: #fff;}
  .refernceAudio p{padding-top: 10px;font-size: 16px; font-weight: 600;}
  .checBtn{font-size: 17px;font-weight:500 !important;display: flex;align-items: center; margin-top: 20px;}
  .checBtn input[type="checkbox"]{height:25px;width:25px; margin-right:10px;}

  .synBtn button{width: 242px;height: 48px;border-radius: 50px;color: #fff;font-size: 18px;font-weight: 600;background-color: #5ACAFA; text-transform: uppercase;transition: all 0.5s;border: none;}
  .synBtn button img{margin-right: 10px;}
  .synBtn button:hover{background-color: #000;}

  .refernceAudio input[type="file"]{ border: 1px dashed #000; height: 150px;text-align: center;/*padding-top: 63px;*/}
  .refernceAudio input[type="file"] input{opacity: 0; position: absolute;}

  .progressbar li{ display: inline-block;}

  .maindash{ padding:35px 0px 30px;}
  .dashData{margin-top: 30px;}

  .cards{background-color: #fff;border-radius: 10px; margin-bottom: 20px;}
  .cards figure, .cards figure img{border-radius: 10px 10px 0 0;position: relative;object-fit: cover;height: 143px;width: 100%;}
  .cards figure a{position: absolute;right: 12px;top: 4px;/*background: #ec3b3b;*/display: inline-block;width: 18px;height: 18px;border-radius: 50%;z-index: 1;text-align: center;line-height: 13px;color: #fff;}
  .cards h3{font-size:16px;color:#000000;font-weight: 600; padding: 0 15px;text-overflow: ellipsis;width: 100%;overflow: hidden;}
  .cards .cardDetail span{width: 30px;height:30px;border-radius: 50%;color: #000;display: inline-block;background-color: #000;line-height: 30px; text-align: center;font-weight:600; flex: 0 0 auto;margin-right: 10px;}
  .cards .cardDetail span img {width: 22px;}
  .cards h6{font-size: 13px;font-weight: 600;color: #000;padding: 0; margin: 0}
  .cards p{font-size: 10px;font-weight: 600;color: #5E5D5D;}
  
  .togleBox {background-color: #555555;padding: 20px;width: 250px;position: absolute;top: 96%;left:7px;z-index:2;border-radius:10px;}
  .togleBox::before{width:0;height:0;border-left:10px solid transparent;border-right:10px solid transparent;border-bottom:10px solid #555;content: "";position: absolute;top: -9px; right:30px;}
  .togleBox ul{padding: 0; margin: 0;}
  .togleBox ul li{display: block;}
  .togleBox ul li:first-child a{color: #FFFCFC;}
  .togleBox ul li.divider{border-bottom: 1px solid #7A7A7A; padding-top:5px;margin-bottom: 15px;}
  .togleBox ul li+li{margin-top:10px;}
  .togleBox ul li a{display: block; color: #DCDCDC;font-size: 13px;font-weight: 600; text-decoration: none;}
  .togleBox ul li a:hover{color: #fff;}
  .togleBox ul li a svg{font-size: 18px; margin-right: 10px;}
  .toggleData a{width: 26px;height: 26px;border-radius: 50%;text-align: center;line-height: 26px;background-color: #fff;}
  .toggleData a svg{font-size: 20px; color: #D8D8D8;}
  .toggleData a.bg-salmon{background-color: #C4CEF5;color: #fff;}
  .toggleData a.bg-salmon svg{color: #fff;}

  .shareIcon{padding: 25px;}
  .shareIcon h5{font-size: 16px;color: #000;font-weight:500; margin-bottom:20px;}
  .shareIcon ul{display: flex;padding: 0;}
  .shareIcon ul li{margin-right: 15px;}
  .shareIcon ul li a{width: 50px;height: 50px;border-radius: 50%;font-size:26px;border: 1px solid #2679DB; color: #2679DB;display: block; text-align: center;line-height: 42px;}
  .shareIcon ul li a.twitter{border-color: #47C2F0;color: #47C2F0;}
  .shareIcon ul li a.insta{border-color: #F76429;color: #F76429;}
  .shareIcon ul li a.linked{border-color:#0077B7;color:#0077B7;}
  .shareIcon ul li a.tele{border-color:#039BE5;color:#039BE5;}
  .shareIcon ul li a.snap{border-color:#F7EB1E;color:#F7EB1E;}
  .shareIcon ul li a.tik{border-color:#FE2C55;color:#FE2C55;}
  .shareIcon ul li a.threads{border-color:#000;color:#000;}

  .copyLink{border-top: 1px solid #ECECEC; margin-top:40px; padding-top: 20px; }
  .copyLink p{font-size: 16px;font-weight: 500; color: #000; margin-bottom: 15px;font-family: 'Montserrat', sans-serif;}
  .copyLink .copyInng .link-text {width: 74%;overflow-wrap: break-word;font-size: 14px;}
  .copyLink .copyInng{border: 1px solid #707070;border-radius: 5px; padding: 8px;font-weight: 500;display: flex;justify-content: space-between;}
  .copyLink .copyInng svg{font-size:35px; border: 1px solid #333; padding: 5px; border-radius: 5px;color: #333; margin-right:15px;}
  .copyLink .copyInng a{background: transparent linear-gradient(298deg, #BC3DF0 0%, #1B3CB7 100%) 0% 0%;border-radius: 5px;color: #fff;display: inline-block;width: 62px;height: 27px;font-size: 13px;font-weight: 500; text-align: center;text-decoration: none;line-height: 24px;float: right;margin-top: 5px;}
  .shareSocial .modal-dialog{max-width: 580px;}
  .bgNone{background: none;}
  .shareSocial iframe{height: 300px; width: 100%;}

  .rename input{border: 1px solid #ccc; border-radius: 5px; width: 100%; padding: 10px;font-weight: 500;color: #000;}
  .rnameBtn button{width: 126px;height: 39px;background: #1B3CB7 0% 0% no-repeat padding-box;border-radius: 28px; color: #fff;font-size: 14px;font-weight: 500;}
  .rnameBtn button.cancel{background-color: #D1D1D1;color: #000;}

  .publish h2{color: #1B3CB7;font-size: 24px;font-weight: 600;}
  .publish p{color:#0E0E0E;font-size:17px;font-weight:500; text-align: center;font-family: 'Montserrat', sans-serif;}

  .allBtn{margin: 0;}
  .allBtn li button{background: #1B3CB7;padding: 10px 35px; color: #fff;font-size: 15px;font-weight: 500;border-radius: 50px;font-family: 'Montserrat', sans-serif;}
  .allBtn li a{background: #1B3CB7;padding: 10px 35px; color: #fff;font-size: 15px;font-weight: 500;border-radius: 50px;font-family: 'Montserrat', sans-serif;display: inline-block;text-decoration: none;}
  .allBtn li +li{margin-left: 20px;}
  .allBtn li button:hover, .allBtn li a:hover{background-color: #000;}


  .choiceTab{display: flex; justify-content: start;padding: 0; margin-bottom: 20px;}
  .choiceTab li button{border-radius: 5px; font-size:15px; color: #000; font-weight: 600; margin-right: 20px; padding:12px 25px; text-align: left;transition: all 0.5s;background: #fff;font-family: 'Montserrat', sans-serif;}
  .choiceTab li button svg{margin-right: 8px;font-size: 16px;}
  .choiceTab li button:hover{background-color: #516FDF;color: #fff;}
  .choiceTab li button.active{background-color: #516FDF;color: #fff;}

  .choseVoiceMain h4{color: #000; margin-bottom: 20px;font-size: 16px;}

  .soundBar{background-color: #4A4A4A; border-radius: 50px; padding:7px 10px 7px 10px; line-height: normal; width: 80%;}
  .soundBar figure{margin: 0;}
  .soundBar button{background: #fff;border-radius: 100%;border: 1px solid #fff; margin-right: 20px;}
  .soundBar button svg{background: none; color: #1D22B5; font-size: 35px; border-radius: 50%;}
  .soundBar input[type="radio"]{width: 30px;height: 30px; margin-left: auto;}

  .comingSoon{background-color: #BC3DF0;min-height: 500px;display: flex;align-items: center;justify-content: center;}
  .videoTitle h3{font-size: 20px;color: #fff;margin-bottom: 15px;}
  .conVideoBox {border: 2px solid #fff;padding: 34px;border-radius: 5px; margin-bottom: 25px;position: relative;background-color: #fff;}
  .conVideoBox button{ display: none; background-color: #1D22B5;width:30px;height:30px; position: absolute;top:-12px; right:-12px; text-align: center;line-height:22px;font-size: 25px;border-radius: 50%;color: #fff;}
  .conVideoBox iframe {width: 100%;height: 200px;}
  .seeTitle{color: #5ACAFA;margin: 0 auto;font-size:19px;font-weight: 500;text-decoration: none; margin-top:30px; display: inline-block;}
  .seeTitle:hover{color: #F3A76D;text-decoration: underline;}

  .customUpload{z-index: 1; position: relative; background-color: #fff;border-radius: 5px;border: 1px dashed #000;}
  
  .file-border{border: 1px solid #ccc;width: 100%;padding: 0 5px;height:150px;text-overflow: clip;opacity: 0;}
  .upload-btn{display: block;width: calc(100% - 0px);height: 150px;position: absolute;top: 0;z-index: -1;display: flex;align-items: center;justify-content: center;flex-direction: column;}
  .upload-btn::before{position: relative;content: "Browse";background: #009140;color: #fff;padding:10px 40px;border-radius:25px;line-height: 24px;text-transform: uppercase;top: -1px;left: 0;font-size:15px;font-weight: 500;}

/*============================= Face Search Tool ==========================*/
  .file-upload {position: relative;display: inline-block;background: rgb(188,61,240);background: linear-gradient(180deg, rgba(188,61,240,1) 0%, rgba(27,60,183,1) 100%);border-radius: .4em;transition: background .3s;width: 100%; height: 450px;border:1px dashed #fff; cursor: pointer;}
  .file-upload__label {display: flex;align-items: center;justify-content: center;padding: 1em 2em;color: #fff; height: 100%;width: 100%;cursor: pointer;}
  .file-upload__input {position: absolute;left: 0;top: 0;right: 0;bottom: 0;font-size: 1;width:100%;height: 100%;opacity: 0;}
  .fileTitle {text-align: center;}
  .fileTitle{font-size: 20px; font-weight: 500;}
  .uploadImage{background: #404040;}
  .uploadImage p{font-size: 15px; margin-top: 10px;}

  .file-upload_2 {position: relative;display: inline-block;background: rgb(188,61,240);background: linear-gradient(180deg, rgba(188,61,240,1) 0%, rgba(27,60,183,1) 100%);border-radius: .4em;transition: background .3s;width: 100%; height: 450px;border:1px dashed #fff; cursor: pointer;}
  .file-upload__label_2 {display: flex;align-items: center;justify-content: center;padding: 1em 2em;color: #fff; height: 100%;width: 100%;cursor: pointer;}
  .file-upload__input_2 {position:absolute;left:0;top: 0;right: 0;bottom: 0;width:100%;height: 100%;opacity:0; z-index:999;}
  .fileTitle_2 {text-align: center;}
  .fileTitle_2{font-size: 20px; font-weight: 500;}
  .uploadImage_2{background: #404040;}
  .uploadImage_2 p{font-size: 15px; margin-top: 10px;}



  .soundBarDet{background: #404040;border: 1px solid #817F7F; padding:30px 20px;border-radius:15px; margin-top:40px;}
  .soundBarDet h3{font-size: 20px; color: #fff; margin-bottom:30px;}
  .soundBar_2{background: #000; display: flex; justify-content: space-between; padding:5px 10px;border-radius: 8px;}
  .soundBar_2 div{background: #F3A76D;border-radius: 5px; width:30px; height:30px;}
  .soundBar_2 div:nth-child(2){width: 15px;}
  .soundBar_2 div:nth-child(3){width: 150px;}
  .soundBar_2 div:nth-child(4){width: 20px;}
  .mergeBtn{text-align: right;}
  .mergeBtn button{width: 242px;height: 48px;margin-top: 30px;border-radius: 50px;color: #fff;font-size: 18px;font-weight: 600;background-color: #000;text-transform: uppercase;transition: all 0.5s;border: none;}
  /*============================= Congo popup==========================*/
  .congo{}
  .congo figure{position: relative;display: flex;align-items: center;justify-content: center;}
  .congo figure figcaption{position: relative; width: 133px;height: 133px;border-radius: 50%;background-color: #FFCFAA; position: absolute; display: flex;align-items: center; justify-content: center;z-index: 1;border:7px solid #fff;box-shadow: 0 0 15px rgba(0, 0, 0, 0.16);font-size: 56px;color: #1B3CB7;font-weight: bold;flex-direction: column;line-height: normal;}
  .congo figure figcaption span{display: block;font-size: 17px;text-transform: uppercase;font-weight: 600;}
  .btnClose{position: absolute;right: 10px;top: 10px;}
  .publish.congo h2{font-size: 35px; margin-top:35px; font-weight: bold;}
  .publish.congo p{width: 80%; margin: 0 auto;}
  header.headerHome .loginBar ul li button.points{border-radius: 50px;border: 0.5px solid #FFDA7A;background: linear-gradient(180deg, #515151 0%, #000 100%);box-shadow: 0px 5px 4px 0px rgba(0, 0, 0, 0.95) inset;width: 110px;height: 40px;font-size:16px;line-height: 33px;text-align: left;padding: 0 6px;}    
  .points img{width:30px; margin-right:10px;}

  /*============================= Wallet popup ==========================*/
  .wallet{padding: 34px;}
  .wallet figure{margin-right: 40px;}
  .wallet h6{font-size: 16px;color: #7A7A7A;font-weight: 500;}
  .wallet h2{font-size: 32px;color: #000;font-weight: 600;}
  .wallet a{width: 126px; height: 40px; line-height: 40px; color: #fff;background-color: #1B3CB7;border-radius: 50px;text-align: center;text-decoration: none;font-weight: 500;}
  .wallet a:hover{background-color: #000;}

  .history h3{font-size: 15px;font-weight: 500;color: #000;}
  .history ul{padding: 0; margin: 0;}
  .history ul li{border: 1px solid #B6B6B6;border-radius: 5px; padding:6px 8px; margin-bottom: 15px;}
  .history ul li span{background-color: #EEEEEE;width: 37px;height: 37px;line-height: 37px;text-align: center;border-radius: 5px;}
  .history ul li p{margin: 0;font-family: 'Montserrat', sans-serif;font-size: 15px;font-weight: 400;}
  .history ul li:last-child span{background-color: #656565;}
  .shareWtFrnd button{/*background: transparent linear-gradient(111deg, #F3A76D 0%, #BC3DF0 100%) 0% 0% no-repeat padding-box;*/ background: rgb(188,61,240);background: linear-gradient(180deg, rgba(188,61,240,1) 0%, rgba(27,60,183,1) 100%); width: 100%;height: 45px;border-radius: 5px;font-size: 15px;font-weight: 600;color: #fff;display: flex;align-items: center; padding: 0 0 0 10px; margin-bottom: 25px;}
  .shareWtFrnd button img{margin-right: 15px;}

    /*============================= Share With Friends popup ==========================*/
  .search{border: 1px solid #707070;border-radius: 50px;position: relative;display: flex; padding: 0 15px; align-items: center; height: 48px;}
  .search input{border: none;width: 100%; height: 40px;border-radius: 50px; margin-left:12px;}
  .search button{border: none;width: auto; height: 40px;border-radius: 50px; background: none;font-size:25px;color: #BEBEBE;}
  
  .suggest {margin-top: 60px;}
  .suggest h2{font-size: 16px;text-transform: uppercase;color: #000; margin:30px 0 10px; font-weight: 600;}
  .sugBox{border: 1px solid #ccc; border-radius: 5px; padding: 8px;min-width: 230px; margin-right:30px;}
  .sugBox figure{margin-right: 20px !important;}
  .sugBox figure,.sugBox figure img{width: 45px;height: 45px;border-radius: 50%; margin: 0;}
  .sugBox h4{font-size: 16px;font-weight: 600;color: #000; margin: 0;}
  .sugBox span{font-size:13px;font-weight:400;color: #A8A6A6;}

/*============================= Pckage Page ==========================*/
  .packages{padding: 70px 0;}
  .packageBox{background: transparent linear-gradient(0deg, #3F8D14 0%, #F3A76D 100%) 0% 0% no-repeat padding-box; border-radius: 0 0 20px 20px; padding:0;min-height:600px;margin: 0 8px;position: relative;top: -4px;color: #fff;}
  .packageBox.pro{background: transparent linear-gradient(0deg, #1B3CB7 0%, #5ACAFA 100%) 0% 0% no-repeat padding-box;}
  .packageBox.enterprise{background: transparent linear-gradient(0deg, #BC3DF0 0%, #F3A76D 100%) 0% 0% no-repeat padding-box;}
  .packageBox:after{width: 0;height: 0;border-top: 60px solid transparent;border-bottom: 60px solid transparent;border-right: 30px solid #000;content: "";position: absolute;top: 52px;right: 0;}
  .packageBox:before{width: 0;height: 0;border-top: 60px solid transparent;border-bottom: 60px solid transparent;border-left: 30px solid #000;content: "";position: absolute;top: 52px;left: 0;}
  .packageBox h2{font-size: 50px;font-weight: bold;padding:70px 0 14px 0;}
  .packageBox h2 span{font-size: 26px;font-weight:600;}
  .packageBox p{font-weight: 500; line-height: 25px;font-size: 16px; padding-bottom: 25px;}
  .packTitle{font-size: 25px; background-color: #fff;color: #000;font-weight: 600; text-transform: uppercase;border-radius: 23px 23px 20px 20px;text-align: center;position: relative;z-index: 1;padding-top: 20px;}
  .packTitle pre{font-size: 25px; margin-bottom: 0;font-family: 'Montserrat', sans-serif !important;}
  .packTitle span{display: block;}
  .packTitle::after {height:110px;width: 100%;content: "";display: block;border-radius: 0 0 500% 500%;position: absolute;z-index: -1;top:21px;background: #fff;box-shadow: 0px 3px 2px #00000026;}
  .benefitLsit{padding: 0px;}
  .benefitLsit li{font-weight: 500;padding-bottom: 15px;position: relative; padding-left: 25px;}
  .benefitLsit li::before{width:10px;height:10px;border-radius: 50%;left: 0; top:6px; position: absolute;background-color: #fff;content: "";}
  .pointNumber{text-align: center;}
  .pointNumber h6{color: #fff;font-size: 18px;font-weight: 600; margin-bottom: 10px;}
  .pointNumber span{width: 80px;height: 80px;border-radius: 50%; text-align: center;line-height:72px;font-size: 26px;font-weight: bold;color: #1B3CB7; display: inline-block;background: #fff;border: 5px solid rgba(243, 167, 109, 0.35);box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);}

  .updateBtn{position: absolute;bottom: 0; margin: 0 auto 20px;width: 100%;text-align: center;left: 0;}
  .updateBtn button{background: transparent linear-gradient(180deg, #5ACAFA 0%, #1B3CB7 100%) 0% 0%;width: 158px;height: 36px;border-radius: 25px;color: #fff;font-size: 14px;font-weight: 600;}
  .updateBtn button:hover{background: transparent linear-gradient(180deg, #1B3CB7 0%, #5ACAFA 100%) 0% 0%;}
  .updateBtn button.proBtn{background: transparent linear-gradient(358deg, #BC3DF0 0%, #1B3CB7 100%) 0% 0% no-repeat padding-box;}
  .updateBtn button.proBtn:hover{background: transparent linear-gradient(358deg, #1B3CB7 0%, #BC3DF0 100%) 0% 0% no-repeat padding-box;}
  .updateBtn button.enterBtn{background: transparent linear-gradient(180deg, #BC3DF0 0%, #F3A76D 100%) 0% 0% no-repeat padding-box;}
  .updateBtn button.enterBtn:hover{background: transparent linear-gradient(180deg, #F3A76D 0%, #BC3DF0 100%) 0% 0% no-repeat padding-box;}

  .aiPoints{padding-top: 30px;}
  .aiPoints p{font-size: 13px; color: #fff; margin: 0 30px 0 0;}


/*============================= NewChat Page ==========================*/
.chatTab{text-align:center; position:relative;}
.chatTab .backBtn{left:0px; bottom:22px;}
.chatTab ul{background:#363636; border-radius:5px; padding:5px; display:inline-block; min-width:450px; position: relative;}
.chatTab ul li{display:inline-block; width:50%;}
.chatTab ul li button{width:100%; font-size:16px; font-weight:600; color:#fff; background:none; height:42px;}
.chatTab ul li button.active{background-color: #1B3CB7;border-radius: 5px;}

.chatTab ul li:hover .intBox{display:block;}

.intBox{width:450px; margin:0 auto 0; padding-top:20px;  text-align:left; position:absolute; left:0; top:45px; display:none;}
.intBoxMain{background:#373737; border:1px solid #5A5858; padding:20px; border-radius:10px;}
.intBox .textLine{font-size:14px;}
.intBox .textLine > span{font-size:13px; font-weight:600; color:#DDD; display:block; margin-top:10px;}
.intBox p{color:#fff; font-size:16px;}
.intBox select{padding:0 10px; height:42px; border-radius:5px; border:none; background:url(/public/images/arrowDown.png) no-repeat right center #fff; width:100%; -moz-appearance:none;-webkit-appearance:none}
.switch {position: relative;display: inline-block;width: 45px;height: 23px;}
.switch input { opacity: 0;width: 0;height: 0;}
.slider {position: absolute;cursor: pointer;top: 0;left: 0;right: 0;bottom: 0;background-color:#FFFFFF;-webkit-transition: .4s;transition: .4s;}
.slider:before {position: absolute;content: "";height:18px;width:18px;left: 3px;bottom:2px;background-color: #1B3CB7;-webkit-transition: .4s;transition: .4s;}
input:checked + .slider {background-color: #F3A76D;}
input:focus + .slider {box-shadow: 0 0 1px #2196F3;}
input:checked + .slider:before {-webkit-transform: translateX(20px);-ms-transform: translateX(20px);transform: translateX(20px);background-color: #fff;}
.slider.round {border-radius: 34px;}
.slider.round:before {border-radius: 50%;}

hr.line{border-top:1px solid #b1acac; margin:20px 0 15px;}

.userPersona{margin-bottom:10px;}
.userPersona p{margin-bottom:10px;}

.personaDrop .dropdown a{color:#000; font-weight:500;}
.personaDrop .dropdown a:hover{background:#1B3CB7; color:#FFF;}
.personaDrop .dropdown button{background:#FFF; color:#000; border:0px; font-size:15px; text-align:left; font-weight:400; display:flex; align-items:center; justify-content:space-between;}
.personaDrop .dropdown .dropdown-menu{width:100%;}

.personaDrop a.dropdown-item{position:relative;}
.personaDrop a.dropdown-item .userHover{width:220px; position:absolute; left:100%; margin-left:10px; bottom:-50px; border-radius:10px; background:#FFF; padding:10px; text-align:center; display:none;}
.personaDrop a.dropdown-item:hover .userHover{display:block;}
.personaDrop a.dropdown-item .userHover img{height:150px; width:150px; border-radius:100%; object-fit:cover; margin-bottom:10px;}
.personaDrop a.dropdown-item .userHover p{white-space:normal; color:#000; text-align:center; margin:0; font-size:13px; font-weight:600; line-height:16px;}


.inputChatBox{position:fixed; width:100%; left:0px; bottom:50px; z-index:9;}

.chatBox {width:100%; margin:0 auto; position:relative;}
.chatBox input {width:100%; border-radius:5px; background:#FFF; height:60px; color:#000; padding:0 60px 0 15px;}
.chatBox button{position: absolute; right:17px; background: none; color: #9A9A9A; font-size:25px; top:10px;}

/*============================= My Chat Page ==========================*/

.userChatIcon{width: 50px;height: 50px;background-color: #F3A76D;border-radius: 15px;color: #000;font-size: 15px;font-weight: 600; text-transform: uppercase;text-align: center;line-height: 50px; margin-right: 25px;flex: 0 0 auto}
.userChatMsgBox{border-radius: 15px;font-weight: 500;color: #F8F8F8;background-color: #757575; display: inline-block;min-height: 50px; padding:12px;min-width: 250px;}

.ChatBotIcon{width: 50px;height: 50px;background-color: #5E5E5E;border-radius: 15px;color: #000;font-size: 15px;font-weight: 600; text-transform: uppercase;text-align: center;line-height: 50px; margin-right: 25px;flex: 0 0 auto;}
.ChatBotMsgBox{border-radius: 15px;color: #F8F8F8;background-color:#D0EDF9; display: inline-block;min-height: 50px; padding:12px;min-width: 250px;}
.ChatBotMsgBox p{color: #000;font-weight: 500;}

/*============================= My Chat Page ==========================*/
.mainChatHistort{background-color:#212121; padding:10px 10px 80px; min-height:calc(100vh - 180px); position:absolute; top: 65px; right: 10px; width: 15%;}
.mainChatHistort h6 {margin-left: 10px;}
.hisBox h6{font-size: 13px;color: #CFCFCF; font-weight: 500;margin-bottom: 15px;}
.hisBox ul{padding: 0;}
.hisBox ul li{padding: 0;font-size: 12px;font-size: 500; margin-bottom:10px;color: #fff;}
.hisBox ul li svg{margin-right: 10px;font-size: 15px;}
.hisBox ul li button{background: none; color: #fff;}
.hisBox ul li div {margin-left: 10px;}
.clearChat{position:absolute; bottom:10px; left:0px; padding:0 10px; width:100%;}
.clearChat button{background:#1B3CB7; border-radius:5px;color:#fff;font-weight:500; text-align:center; padding:8px 15px; width:100%;}
.clearChat.top{position:relative; margin:20px 0 10px;}

/*============================= Extract document Page ==========================*/
.docSearch{background-color: #646464;border-radius: 5px;height: 56px;width: 100%; padding: 5px 20px; display: flex;align-items: center;}
.docSearch input{background:none; width: 100%; height: 100%; color: #fff;}
.docSearch button{background: none; margin-right:20px;font-size: 27px;color: #fff;}
.creatNew{background:#5ACAFA; font-size:16px; font-weight:500; border:none; height:56px; color:#fff; width:100%; padding:10px 25px; text-align:left; border-radius:5px; display:flex; align-items:center; justify-content:space-between;}
.creatNew > span {background: #FFF;color: #000;height: 30px;width: 30px;text-align: center;line-height: 28px;border-radius: 100%;}
.viewBtn.blue{padding: 4px 12px;font-size: 13px;min-width: auto;font-weight: 600;border-radius: 30px;border: 0px; background:#1B3CB7;}
.viewBtn.blue:hover{background:#5ACAFA; color:#FFF;}

.dropDownMenu button.dropdown-toggle.btn{width: 100%;background-color: #5ACAFA;font-size: 16px;font-weight: 500;border: none; height: 56px;color: #fff;position: relative;z-index: 1111;}
.dropDownMenu .dropdown-menu{width: 100%;border-radius: 0 0 15px 15px; padding: 25px 0;margin-top: -8px;}
.dropDownMenu .dropdown-menu a{color: #000; font-size: 15px;font-weight: 500; padding-bottom: 15px;}
.dropDownMenu .dropdown-menu a span{font-size: 12px;color: #818181;font-weight:400;display: inline-block; padding-left:25px;}
.notFoundData{padding-top:150px;}
.notFoundData h2{font-size: 36px;font-weight: 500;color: #fff;}
.notFoundData p{font-size: 18px;font-weight: 500;color: #fff;}
.uploadTable table{  border-radius: 6px 6px 6px 6px;-moz-border-radius: 6px 6px 6px 6px;-webkit-border-radius: 6px 6px 6px 6px;}
.uploadTable table tr th,.uploadTable table tr td{padding: 15px 23px;}
.uploadTable table tr td{font-size: 14px;font-weight: 500;}
.uploadTable table tr th{font-size: 15px;font-weight: 500;color: #000;}
.uploadTable table tr td input{height: 20px;width: 20px; margin-right: 15px;}
.uploadTable table tr td a{color: #F3A76D;}

button.needHelp{color: #fff;font-size: 16px;font-weight: 500;background: none;text-decoration: underline;}

/*============================= Dub Videos Page ==========================*/
.stepBox{background-color: #4D4D4D;border-radius: 15px;padding: 15px;text-align: center; height: 100%;}
.stepBox figure,.stepBox figure img{border-radius: 15px;}
.stepBox span{margin: 20px auto; display:flex;align-items: center;justify-content: center;width: 70px;height: 70px;font-size: 30px;font-weight: 600;background-color: #F3A76D;border: 5px solid #fff;color: #fff;border-radius: 50%;box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);}
.stepBox p{font-size: 15px;font-weight:500;color: #fff;text-align: center;line-height: 30px;}
.stepBox p i{font-style: normal;color: #F3A76D;font-size: 17px;}

.refernceAudio video{width: 100%; border-radius: 15px;}
.watchTitle{font-size: 20px;color: #fff;font-weight: 600; margin-top: 30px;}
.watchTitle span{display: block;font-size: 15px; font-weight: 600;}

.backBtn{background-color: #0080fc;border-radius: 5px;color: #fff; padding: 5px 10px;position: absolute;bottom: 0;}
.refernceAudio .sizedecrese .upload-btn{height:86px;}
.refernceAudio .sizedecrese .upload-btn:before{content: "Audio Upload";}
.refernceAudio .sizedecrese input[type="file"] {height: 86px;}


/*============================= Seo Scribe Page ==========================*/
.seoform input{background: #313131;border: none; height: 48px;border-radius: 5px;color: #fff;font-size: 15px;font-weight: 500;}
.seoform input::placeholder{color: #fff;}
.seoform input:focus{background: #313131;box-shadow: none; color: #fff;}
.btnList{display: flex;padding: 0;}
.btnList li{margin-right: 15px;}
.btnList li a{border: 1px solid #545454;border-radius: 5px;text-decoration: none;color: #545454;font-size: 16px;font-weight: 600; display: block; padding:10px 20px;text-transform: uppercase;}
.btnList li a.active, .btnList li a:hover{color: #fff;border-color: #fff;}
.accordioMenu .accordion-item{margin-bottom: 15px;border: none;border-radius: 5px; background: #232121;}
.accordion svg{fill: #fff;}
.accordioMenu .accordion-item button{border: none;font-size: 16px;font-weight: 600;border-radius: 5px!important;background: #434242;color: #fff;}
.accordioMenu .accordion-item:last-of-type .accordion-button.collapsed{border: 5px;}
.accordioMenu .accordion-item button:focus{box-shadow: none;}
.accordioMenu .accordion-button:not(.collapsed){background: #1B3CB7; color: #fff;box-shadow: none;}
.accordioMenu .accordion-collapse{background:#232121; color: #fff;}
.accordioMenu .accordion-body{border-width: 0 1px 1px 1px;border-style: solid; border-color: transparent #ccc #ccc #B1B1B1;margin-top: -5px;}
#cke_editor2, .cke_inner, .cke_reset {min-height: 400px;}
.accordioMenu .accordion-button:after {background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23ffffff'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>") !important;}

.sideKeywords{background: #fff;position: absolute;right: 0; height: 100%; top: 0;}
.sidebar { overflow: hidden; width:500px;box-shadow: 0 8px 8px -4px;transform: translateX(0);transition: width 1s ease;background: #fff;margin-left:30px;background: #212121;}
.showSidebar {width: 0;}
.accordioMenu{width: calc(100% -500px);transition: width 1s ease;}

.sidebar .toggle-btn{background:none;}
.keyHeader h3{color: #fff;font-size: 20px;font-weight: 600;}
.keyHeader{border-bottom: 1px solid #707070;}
.keywordMain{overflow: scroll;height: 500px;}
.keywordMain table, .keywordMain table tr td,.keywordMain table tr th{background: none; color: #fff;font-size: 15px;font-weight: 500; padding:12px 12px;border-color: #707070;}
.keywordMain table tr th{text-align: right;}
.keywordMain label {display: flex;}
.keywordMain input[type="checkbox"] {appearance: none;background-color: #212121;margin: 0;font: inherit;color: currentColor;width: 23px;height: 23px;border: 1px solid #fff;border-radius:5px;transform: translateY(-0.075em);display: grid;place-content: center;margin-right: 10px;}
.keywordMain input[type="checkbox"]::before {content: "";width:13px;height:13px;transform: scale(0);transition: 120ms transform ease-in-out;box-shadow: inset 1em 1em #5ACAFA;clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);}
.keywordMain input[type="checkbox"]:checked::before {transform: scale(1);}
.blueBox, .greenBox{border: 1px solid #5ACAFA;border-radius: 8px;width: 54px;height: 30px;text-align: center; line-height: 30px;}
.greenBox{border-color: #62B833;}
.optButton{text-align: center; margin-top: 50px;}
.optButton button{background: #1B3CB7;font-size: 16px;color: #fff;font-weight: 600; min-width: 250px; height: 40px; text-align: center;line-height: 40px;border-radius: 5px;transition: all 0.5s;}
.optButton button:hover{background-color: #000;}

.call-btn-mobile.third, .location-btn-mobile.third {display: none !important;}

/* .btnList li:last-child a{border-color: #62B833; color: #fff;} */

    /*========================= Media Query ======================*/
    @media only screen and (max-width:1380px) {
      /*.overFlow {overflow-x: scroll;}
      .tabing{overflow-x: scroll;width:1800px;margin: 0;}*/
      .btnList li a{font-size: 15px; padding: 10px 18px;}
    } 
    @media only screen and (max-width:1199px) {
      .overFlow {overflow-x: scroll;}
      /* .tabing{overflow-x: scroll;width:100%;margin: 0;} */
      #cke_editor2, .cke_inner, .cke_reset {min-height: inherit;}
      .sidebar {right: 0; width: 350px; transition: all 0.5s; top: 60px;position: absolute;}
      .showSidebar{width: 350px;right: -350px;}
    }
    @media only screen and (max-width:991px) {
      .maindash{padding-left: 0;}



      .profileMain .toggleBtn{right:0px;}
      .profileMain .toggleBtn .humber{display:none;}
      .profileMain .toggleBtn .cross{display:block;}
      
      .profileMain .menuSectionLeft{left:0px;}

      .profileMain.show .toggleBtn{right:-45px;}
      .profileMain.show .toggleBtn .humber{display:block;}
      .profileMain.show .toggleBtn .cross{display:none;}
      .profileMain.show{padding-left:0;}
      .profileMain.show .menuSectionLeft{left:-300px;}
      


      
    }
    @media only screen and (max-width: 767px) {
      .cards figure, .cards figure img{width: 100%;}
      .userProfile span{display:none ;}
      header.headerHome .loginBar ul li button.points{width:95px;font-size: 14px;}
      .points img{margin-right: 4px;}
      header.headerHome .loginBar ul li + li {margin-left: 13px;}
      .createAi i{display: none;}
      .createAi{min-width: 115px;}
      .createAi img {margin-right: 5px;}
      .createAi{font-size: 13px;}
      .profileMain{padding-right: 0;}
      .customUpload img{height: auto;}
      .btnList{margin-top: 20px;}
      .btnList li a{font-size: 14px; padding: 10px 15px;}
      .file-upload{height: 250px; margin-bottom: 20px;}
      

    }
    @media only screen and (max-width:575px) {
    .btnList li a {font-size: 12px;padding: 10px 11px;}
    .btnList li {margin-right: 8px;}
  }
    @media only screen and (max-width:480px) {
      .menuHome {margin-left: 0px;}
      .allBtn{flex-wrap: wrap;}
      .allBtn li{margin-bottom: 20px;}
      .btnList{flex-wrap: wrap;}
      
    }